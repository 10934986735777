import classNames from "classnames";
import React, { PropsWithChildren } from "react";

import { ITextProps, Text } from "../Text/Text";
import { SRColors, TypographyFontSize } from "../../types";
import styles from "./_Paragraph.module.scss";

export interface IParagraphProps extends Omit<ITextProps, "tag"> {
  size?: TypographyFontSize;
}

/**
 * Used to display body text in a `<p>` tag. By default, `Paragraph` is lighter weight and smaller than the `Header` component.
 */
export const Paragraph: React.FC<PropsWithChildren<IParagraphProps>> = ({
  size = "sm",
  weight = "light",
  verticalMargin = "sm",
  className,
  color = SRColors.Neutral90,
  ...props
}) => (
  <Text
    tag="p"
    className={classNames(styles.paragraph, className)}
    size={size}
    weight={weight}
    verticalMargin={verticalMargin}
    color={color}
    {...props}
  />
);

Paragraph.displayName = "Paragraph";
