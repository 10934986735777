import React from "react";
import * as yup from "yup";
import _ from "lodash";
import { FORM_ERROR, FormApi, getIn } from "final-form";
import { Form, FormProps, FormRenderProps } from "react-final-form";
import createFocusDecorator from "final-form-focus";

import Logger from "js-logger";
import { mapYupErrorToKeyValue, yupValidators } from "../../utils";

export interface ISimpleFormProps<T extends object, R = void> extends Omit<FormProps, "onSubmit"> {
  onSubmit: (values: T, changedValues: Partial<T>, form: FormApi<T>) => Promise<R>;
  onError?: (errors: Error) => void;
  initialValues?: Partial<T>;
  validationSchema?: yup.ObjectSchema<T>;
  abortEarly?: boolean;
  children: (props: FormRenderProps<T>) => React.ReactNode;
}

yupValidators();

const isFocusableInput = (inp: HTMLFormElement) => inp && typeof inp.focus === "function";
const isFormErrorLabel = (inp: Element) => inp && typeof inp.getAttribute("data-for-field") !== "undefined";

type FocusableElement = HTMLInputElement | Element;

function isFormElement(element: FocusableElement): element is HTMLInputElement {
  return element instanceof HTMLInputElement;
}
/**
 * Finds the element by looking if the attribute is existing in the errors object
 */
const findElement = (inputs: FocusableElement[], errors) =>
  inputs.find(input => {
    if (isFormElement(input)) {
      return input.name && getIn(errors, input.name);
    }
    const attr = input.getAttribute("data-for-field");
    return attr && getIn(errors, attr);
  });

/**
 * Gets all the inputs inside all forms on the page
 */
const getAllInputs = (): HTMLInputElement[] => {
  if (typeof document === "undefined") {
    return [];
  }
  return [...document.forms].reduce<HTMLInputElement[]>(
    (acc, form) => acc.concat([...form].filter(isFocusableInput) as HTMLInputElement[]),
    []
  );
};

/**
 * Gets all the errors inside all forms on the page
 */
const getAllErrorMessages = (): Element[] => {
  if (typeof document === "undefined") {
    return [];
  }
  return [...document.getElementsByClassName("sr-form-error")].filter(isFormErrorLabel);
};

const customGetAllViableElements = (): FocusableElement[] => {
  const inputs = getAllInputs();
  const errors = getAllErrorMessages();
  // focus on labels first
  return [...errors, ...inputs] as FocusableElement[];
};
const defaultDecorators = [createFocusDecorator(customGetAllViableElements, findElement)];

/**
 * A component that abstracts away some common final-form operations and provides
 * some nicer typing. This is totally optional, more complex forms may have to
 * use final form directly.
 */
export function SimpleForm<T extends object = {}, R = void>({
  children,
  abortEarly = false,
  validationSchema,
  onSubmit,
  onError,
  render,
  validate,
  decorators = [],
  ...remainingProps
}: ISimpleFormProps<T, R>) {
  const submitHandler = async (values: any, form: FormApi<T>) => {
    Logger.debug(`Form Submitted`);
    try {
      const changedValues = _.pick(values, Object.keys(form.getState().dirtyFields));
      await onSubmit(values as T, changedValues as Partial<T>, form);
    } catch (e) {
      Logger.debug(`Form submission error`, e);
      if (onError) {
        onError(e);
      }
      return { [FORM_ERROR]: e.message };
    }
  };

  const validationHandler = (values: any): object => {
    if (!validationSchema) {
      return {};
    }

    try {
      validationSchema.validateSync(values, { abortEarly });
      Logger.debug(`Form is valid`);
      return {};
    } catch (e) {
      Logger.debug(`Form validation errors`, e);
      return mapYupErrorToKeyValue(e);
    }
  };

  return (
    <Form<T>
      onSubmit={submitHandler}
      validate={validate || validationHandler}
      render={render || children}
      decorators={[...defaultDecorators, ...decorators]}
      {...remainingProps}
    />
  );
}
