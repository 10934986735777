import React, { FC, PropsWithChildren } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuthenticatedUserContext } from "@serverready-ui/auth";
import { storeLocations } from "./locations";

/**
 * Prevents logged in users from accessing these routes
 */
export const UnauthenticatedLayout: FC<PropsWithChildren> = ({ children }) => {
  const { isLoggedIn } = useAuthenticatedUserContext();
  if (isLoggedIn) {
    return <Navigate to={storeLocations.Dashboard.buildPath({})} />;
  } else {
    return <>{children || <Outlet />}</>;
  }
};
