import { IUserApiProfileResponse } from "@serverready-ui/api";
import { IAuthenticatedUserContext } from "../context";

export const toAuthenticatedUserContext = (user: IUserApiProfileResponse): IAuthenticatedUserContext => {
  return {
    isLoggedIn: true,
    user: {
      id: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      createdAt: user.createdAt,
      updatedAt: user.updatedAt,
      accountId: user.accountId,
    },
  };
};
