import classNames from "classnames";
import React, { CSSProperties, PropsWithChildren } from "react";
import { Column, ISpacingProps, Row, Spacing } from "../../layout";
import { SRColor } from "../../types";
import { getMargins } from "../../layout/Spacing/getMargins";
import { Header } from "../../typography";
import styles from "./_Card.module.scss";

export interface ICardProps extends ISpacingProps {
  id?: string;
  borderColor?: SRColor;
  border?: boolean;
  className?: string;
  backgroundColor?: SRColor;
  title?: string;
  titleAside?: React.ReactElement;
  disabled?: boolean;
}

export const Card: React.FC<PropsWithChildren<ICardProps>> = ({
  id,
  className,
  children,
  title,
  titleAside,
  border = true,
  borderColor,
  backgroundColor,
  disabled,
  padding = "md",
  ...spacingProps
}) => {
  const style: CSSProperties = {};
  if (backgroundColor) {
    style.backgroundColor = backgroundColor;
  }
  if (borderColor) {
    style.borderColor = borderColor;
  }

  return (
    <Spacing
      id={id}
      style={style}
      className={classNames("sr-card", styles.card, className, {
        [styles.noBorder]: !border,
        [styles.disabled]: !!disabled,
      })}
      padding={padding}
      {...(!title ? spacingProps : getMargins(spacingProps))}
    >
      {!!title ? (
        <>
          <Spacing padding="md" className={styles.cardHeader}>
            <Row verticalMargin="none" horizontalAlign="space-between" verticalAlign="center">
              <Column>
                <Header margin="none" weight="regular" size="h3">
                  {title}
                </Header>
              </Column>
              <Column>{titleAside}</Column>
            </Row>
          </Spacing>
          <Spacing {...spacingProps}>{children}</Spacing>
        </>
      ) : (
        children
      )}
    </Spacing>
  );
};

Card.displayName = "Card";
