import React, { FC, PropsWithChildren } from "react";
import { Position } from "@serverready-ui/design-language/dist/layout";
import { NAVBAR_HEIGHT, SIDEBAR_WIDTH } from "@serverready-ui/core";
import { SRColors } from "@serverready-ui/design-language/dist/types";

export const AppSideBar: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Position
      mobileRelative={false}
      fixed
      left={0}
      top={NAVBAR_HEIGHT}
      bottom={0}
      style={{
        width: SIDEBAR_WIDTH + "px",
        backgroundColor: SRColors.SurfaceBlackBrandMixDp60,
      }}
    >
      {children}
    </Position>
  );
};
