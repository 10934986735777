import React from "react";
import { ISelectProps, Select } from "@serverready-ui/design-language/dist/input";
import { IFormField } from "@serverready-ui/design-language/dist/types";
import { FormField } from "../FormField/FormField";

type OmitSelectProps = "onBlur" | "onFocus" | "value" | "disabled" | "invalid";

export type ISelectFieldProps<T, Async> = Omit<IFormField, "format" | "formatOnBlur"> &
  Omit<ISelectProps<T, Async>, OmitSelectProps>;

export function SelectField<T, Async = false>({
  name,
  label,
  labelSize,
  explainer,
  disabled,
  options,
  placeholder,
  hidden,
  className,
  marginBottom,
  isSearchable,
  isMulti,
  isClearable,
  onChange,
  ...restProps
}: ISelectFieldProps<T, Async>) {
  return (
    <FormField
      name={name}
      label={label}
      labelSize={labelSize}
      disabled={disabled}
      explainer={explainer}
      hidden={hidden}
      marginBottom={marginBottom}
    >
      {({ meta, input }) => {
        return (
          <Select
            className={className}
            options={options}
            placeholder={placeholder}
            isSearchable={isSearchable}
            isMulti={isMulti}
            isClearable={isClearable}
            isDisabled={disabled}
            invalid={meta.touched && meta.invalid}
            {...restProps}
            {...input}
            onChange={(value, action) => {
              onChange?.(value, action);
              input.onChange(value);
            }}
          />
        );
      }}
    </FormField>
  );
}

SelectField.displayName = "SelectField";
