import classNames from "classnames";
import { FC, PropsWithChildren } from "react";

import { SizeHeader1, SizeHeader2, SizeHeader3, SizeTitle, SRColors } from "../../types";
import { HeaderTag, ITextProps, Text } from "../Text/Text";
import styles from "./_Header.module.scss";

export type HeaderSize = SizeHeader1 | SizeHeader2 | SizeHeader3 | SizeTitle;

interface IHeaderProps extends ITextProps {
  tag?: HeaderTag;
  size?: HeaderSize;
}

/**
 * Used to display header text in a `<h>` tag. By default, the `Header` is heavier weight, larger and darker than the `Paragraph`.
 */
export const Header: FC<PropsWithChildren<IHeaderProps>> = ({
  tag = "h2",
  color = SRColors.Neutral90,
  size = "h2",
  weight = "semibold",
  margin = "sm",
  transform = "none",
  className,
  ...props
}) => (
  <Text
    className={classNames(styles.header, className)}
    tag={tag}
    weight={weight}
    color={color}
    size={size}
    transform={transform}
    verticalMargin={margin}
    {...props}
  />
);

Header.displayName = "Header";
