import { IApiClient } from "./IApiClient";
import { DefaultApiClient, IApiClientOptions } from "./DefaultApiClient";
import { AuthApi, CollectionApi, ImageApi, ProductApi, ServerApi, StoreApi, UserApi } from "./services";

export class Api {
  public readonly Auth: AuthApi;
  public readonly Store: StoreApi;
  public readonly Server: ServerApi;
  public readonly User: UserApi;
  public readonly Collection: CollectionApi;
  public readonly Product: ProductApi;
  public readonly Image: ImageApi;
  public readonly client: IApiClient;

  constructor(baseUrl: string, clientFactory = DefaultApiClient, defaultParameters: IApiClientOptions = {}) {
    this.client = new clientFactory(baseUrl, defaultParameters);
    this.Auth = new AuthApi(this.client);
    this.Store = new StoreApi(this.client);
    this.Server = new ServerApi(this.client);
    this.User = new UserApi(this.client);
    this.Collection = new CollectionApi(this.client);
    this.Product = new ProductApi(this.client);
    this.Image = new ImageApi(this.client);
  }
}
