import { Button, IButtonProps } from "@serverready-ui/design-language/dist/core";
import { FC, PropsWithChildren } from "react";
import { useFormState } from "react-final-form";

interface ISubmitButtonProps extends IButtonProps {}

export const SubmitButton: FC<PropsWithChildren<ISubmitButtonProps>> = ({ children, ...buttonProps }) => {
  const { submitting } = useFormState({ subscription: { submitting: true } });
  return (
    <Button htmlType="submit" type="filled" {...buttonProps} loading={submitting}>
      {children}
    </Button>
  );
};

SubmitButton.displayName = "SubmitButton";
