import { FC, PropsWithChildren } from "react";
import { Checkbox, ICheckboxProps } from "@serverready-ui/design-language/dist/input";
import { IFormField } from "@serverready-ui/design-language/dist/types";
import { FormField } from "../FormField/FormField";

export interface ICheckboxFieldProps extends IFormField, ICheckboxProps {
  defaultValue?: boolean;
}

export const CheckboxField: FC<PropsWithChildren<ICheckboxFieldProps>> = ({
  name,
  className,
  label,
  disabled,
  explainer,
  hidden,
  children,
  value,
  labelSize,
  marginBottom,
  defaultValue,
  ...checkboxProps
}) => {
  return (
    <FormField
      name={name}
      disabled={disabled}
      explainer={explainer}
      hidden={hidden}
      type="checkbox"
      value={value}
      labelSize={labelSize}
      marginBottom={marginBottom}
      defaultValue={defaultValue}
    >
      {({ meta, input }) => (
        <>
          {children}
          <Checkbox
            label={label}
            className={className}
            disabled={disabled}
            invalid={meta.touched && meta.invalid}
            {...checkboxProps}
            {...input}
          />
        </>
      )}
    </FormField>
  );
};

CheckboxField.displayName = "CheckboxField";
