import { FC, PropsWithChildren } from "react";
import { Column, Row } from "@serverready-ui/design-language/dist/layout";
import { AppLink, IAppLinkProps } from "../AppLink/AppLink";
import styles from "./_SideBarLink.module.scss";

interface ISideBarLinkProps extends IAppLinkProps {}

export const SideBarLink: FC<PropsWithChildren<ISideBarLinkProps>> = ({ children, to, exact }) => {
  return (
    <Row>
      <Column>
        <AppLink
          to={to}
          exact={exact}
          horizontalMargin="sm"
          textDecoration="none"
          fullWidth
          fullHeight
          className={styles.sidebarLink}
          inline={false}
        >
          {children}
        </AppLink>
      </Column>
    </Row>
  );
};
