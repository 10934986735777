import { ReactNode } from "react";

/**
 * An object that can be displayed in our select dropdowns (and autocompletes)
 */
export interface ISelectable<T = string, L = string> {
  label: L;
  value: T;
}

export interface ISelectableGroup<T = string, L = ReactNode> {
  label: L;
  options: ISelectable<T>[];
}

export function toSelectable<T = string, L = string>(value: T, label: L): ISelectable<T, L> {
  return { label, value };
}

export function isSelectableGroup<T = string, L = ReactNode>(
  selectable?: (ISelectable<T, L> | ISelectableGroup<T, L>)[]
): selectable is ISelectableGroup<T, L>[] {
  return !!selectable && selectable.length > 0 && "options" in selectable[0];
}
