import { ISpacingProps } from "./ISpacingProps";

export const getMargins = (spacing: ISpacingProps): ISpacingProps => ({
  margin: spacing.margin,
  verticalMargin: spacing.verticalMargin,
  horizontalMargin: spacing.horizontalMargin,
  marginTop: spacing.marginTop,
  marginBottom: spacing.marginBottom,
  marginLeft: spacing.marginLeft,
  marginRight: spacing.marginRight,
});
