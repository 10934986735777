export const FADED_COLOR_PERCENT = 15;

function decimal2hex(d: number) {
  return d.toString(16);
}
function hex2decimal(h: string) {
  return parseInt(h, 16);
}
export const mix = (colorOne: string, colorTwo: string, weight: number): string => {
  colorOne = colorOne.replace(/#/g, "");
  colorTwo = colorTwo.replace(/#/g, "");
  // convert 3-digit hex to 6-digits
  if (colorOne.length === 3) {
    colorOne = colorOne[0] + colorOne[0] + colorOne[1] + colorOne[1] + colorOne[2] + colorOne[2];
  }
  if (colorTwo.length === 3) {
    colorTwo = colorTwo[0] + colorTwo[0] + colorTwo[1] + colorTwo[1] + colorTwo[2] + colorTwo[2];
  }

  let finalColor = "#";
  for (let i = 0; i <= 5; i += 2) {
    // loop through each of the 3 hex pairs: red, green, and blue
    const v1 = hex2decimal(colorOne.substr(i, 2)), // extract the current pairs
      v2 = hex2decimal(colorTwo.substr(i, 2));

    // combine the current pairs from each source color, according to the specified weight
    let val = decimal2hex(Math.ceil(v2 + (v1 - v2) * (weight / 100)));
    while (val.length < 2) {
      val = "0" + val;
    }
    finalColor += val;
  }
  return finalColor;
};
const fade = (color: string, percent: number) => {
  color = color.replace(/#/g, "");
  // calculate the hexidecimal transparency
  const hex = Math.round((255 * percent) / 100).toString(16);
  // convert the hexidecimal transparency to a 2-digit transparency
  const transparency = hex.length === 1 ? "0" + hex : hex;
  // return the rgba color with the specified transparency
  return (
    "#" +
    (color.length === 3
      ? color[0] + color[0] + color[1] + color[1] + color[2] + color[2] + transparency
      : color + transparency)
  );
};

// used in conjunction with the `rgba()` function to create a color with opacity (e.g. rgba($brand, $dp01))
// primarily for modifying the background color of elements on hover, focus, and active states
// but also used for cards and other elements that need to be slightly transparent rather than creating a new color
const dpMap = {
  dp1: 5,
  dp2: 7,
  dp3: 8,
  dp4: 9,
  dp6: 11,
  dp8: 12,
  dp12: 14,
  dp16: 15,
  dp24: 16,
  dp26: 17,
  dp28: 18,
  dp32: 19,
  dp40: 20,
  dp48: 21,
};

export const SRColors = {
  // Main, based on the material design color palette generator with #2b3990 as the main color
  // https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
  Brand900: "#1e277a",
  Brand: "#2b3990", // CTA and main use, 800 tonal weight base
  Brand700: "#33429c",
  Brand600: "#3c4ca8",
  Brand500: "#4254b2",
  Brand400: "#5e6ebd",
  Brand300: "#7b88c9",
  Brand200: "#a0aad8",
  Brand100: "#c5cbe8",
  Brand50: "#e8eaf6",

  // Darkmode Main, based on the material design color palette generator with #0367ff as the main color
  // https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
  BrandLight900: "#2d2dcc",
  BrandLight800: "#1c54ec",
  BrandLight: "#0367ff", // accent color, 700 tonal weight base
  BrandLight600: "#007cff",
  BrandLight500: "#008bff",
  BrandLight400: "#009dff",
  BrandLight300: "#46afff",
  BrandLight200: "#85c6ff",
  BrandLight100: "#b8dcff",
  BrandLight50: "#e1f1ff",
  // Secondary colors go here

  Orange: "#ff9a03",
  get OrangeFaded(): string {
    return mix(this.Orange, this.Neutral, FADED_COLOR_PERCENT);
  },
  Purple: "#9a03ff",
  get PurpleFaded(): string {
    return mix(this.Purple, this.Neutral, FADED_COLOR_PERCENT);
  },
  Pink: "#ff0368",
  get PinkFaded(): string {
    return mix(this.Pink, this.Neutral, FADED_COLOR_PERCENT);
  },

  // Status
  Error: "#B00020",
  ErrorTonal: "#ed323b",
  get ErrorFaded(): string {
    return mix(this.Error, this.Neutral, FADED_COLOR_PERCENT);
  },
  get ErrorDark(): string {
    return mix(this.Error, this.Neutral, 60);
  },
  Warning: "#FBA755",
  WarningTonal: "#fcb76d",
  get WarningFaded(): string {
    return mix(this.Warning, this.Neutral, FADED_COLOR_PERCENT);
  },
  Success: "#32BB8A",
  SuccessTonal: "#60c79f",
  get SuccessFaded(): string {
    return mix(this.Success, this.Neutral, FADED_COLOR_PERCENT);
  },

  // Surface
  Black: "#000",
  SurfaceBlack: "#121212",
  get SurfaceBlackLight(): string {
    return mix(this.SurfaceBlack, this.Neutral, 97);
  },
  get SurfaceBlackLighter(): string {
    return mix(this.SurfaceBlack, this.Neutral, 90);
  },
  get SurfaceBlackBrandMixDp1() {
    return mix(this.SurfaceBlack, this.Brand, dpMap.dp1);
  },
  get SurfaceBlackBrandMixDp2() {
    return mix(this.SurfaceBlack, this.Brand, dpMap.dp2);
  },
  get SurfaceBlackBrandMixDp3() {
    return mix(this.SurfaceBlack, this.Brand, dpMap.dp3);
  },
  get SurfaceBlackBrandMixDp4() {
    return mix(this.SurfaceBlack, this.Brand, dpMap.dp4);
  },
  get SurfaceBlackBrandMixDp6() {
    return mix(this.SurfaceBlack, this.Brand, dpMap.dp6);
  },
  get SurfaceBlackBrandMixDp8() {
    return mix(this.SurfaceBlack, this.Brand, dpMap.dp8);
  },
  get SurfaceBlackBrandMixDp12() {
    return mix(this.SurfaceBlack, this.Brand, dpMap.dp12);
  },
  get SurfaceBlackBrandMixDp16() {
    return mix(this.SurfaceBlack, this.Brand, dpMap.dp16);
  },
  get SurfaceBlackBrandMixDp24() {
    return mix(this.SurfaceBlack, this.Brand, dpMap.dp24);
  },
  get SurfaceBlackBrandMixDp26() {
    return mix(this.SurfaceBlack, this.Brand, dpMap.dp26);
  },
  get SurfaceBlackBrandMixDp28() {
    return mix(this.SurfaceBlack, this.Brand, dpMap.dp28);
  },
  get SurfaceBlackBrandMixDp32() {
    return mix(this.SurfaceBlack, this.Brand, dpMap.dp32);
  },
  get SurfaceBlackBrandMixDp40() {
    return mix(this.SurfaceBlack, this.Brand, dpMap.dp40);
  },
  get SurfaceBlackBrandMixDp48() {
    return mix(this.SurfaceBlack, this.Brand, dpMap.dp48);
  },
  get SurfaceBlackBrandMixDp52() {
    return mix(this.SurfaceBlack, this.Brand, 100 - dpMap.dp48);
  },
  get SurfaceBlackBrandMixDp60() {
    return mix(this.SurfaceBlack, this.Brand, 100 - dpMap.dp40);
  },
  get SurfaceBlackBrandMixDp68() {
    return mix(this.SurfaceBlack, this.Brand, 100 - dpMap.dp32);
  },
  get SurfaceBlackBrandMixDp72() {
    return mix(this.SurfaceBlack, this.Brand, 100 - dpMap.dp28);
  },
  get SurfaceBlackBrandMixDp74() {
    return mix(this.SurfaceBlack, this.Brand, 100 - dpMap.dp26);
  },
  get SurfaceBlackBrandMixDp76() {
    return mix(this.SurfaceBlack, this.Brand, 100 - dpMap.dp24);
  },
  get SurfaceBlackBrandMixDp84() {
    return mix(this.SurfaceBlack, this.Brand, 100 - dpMap.dp16);
  },
  get SurfaceBlackBrandMixDp88() {
    return mix(this.SurfaceBlack, this.Brand, 100 - dpMap.dp12);
  },
  get SurfaceBlackBrandMixDp92() {
    return mix(this.SurfaceBlack, this.Brand, 100 - dpMap.dp8);
  },
  get SurfaceBlackBrandMixDp94() {
    return mix(this.SurfaceBlack, this.Brand, 100 - dpMap.dp6);
  },
  get SurfaceBlackBrandMixDp96() {
    return mix(this.SurfaceBlack, this.Brand, 100 - dpMap.dp4);
  },
  get SurfaceBlackBrandMixDp97() {
    return mix(this.SurfaceBlack, this.Brand, 100 - dpMap.dp3);
  },
  get SurfaceBlackBrandMixDp98() {
    return mix(this.SurfaceBlack, this.Brand, 100 - dpMap.dp2);
  },
  get SurfaceBlackBrandMixDp99() {
    return mix(this.SurfaceBlack, this.Brand, 100 - dpMap.dp1);
  },
  get BlackBrandLightMixDp1() {
    return mix(this.Black, this.BrandLight, dpMap.dp1);
  },
  get BlackBrandLightMixDp2() {
    return mix(this.Black, this.BrandLight, dpMap.dp2);
  },
  get BlackBrandLightMixDp3() {
    return mix(this.Black, this.BrandLight, dpMap.dp3);
  },
  get BlackBrandLightMixDp4() {
    return mix(this.Black, this.BrandLight, dpMap.dp4);
  },
  get BlackBrandLightMixDp6() {
    return mix(this.Black, this.BrandLight, dpMap.dp6);
  },
  get BlackBrandLightMixDp8() {
    return mix(this.Black, this.BrandLight, dpMap.dp8);
  },
  get BlackBrandLightMixDp12() {
    return mix(this.Black, this.BrandLight, dpMap.dp12);
  },
  get BlackBrandLightMixDp16() {
    return mix(this.Black, this.BrandLight, dpMap.dp16);
  },
  get BlackBrandLightMixDp24() {
    return mix(this.Black, this.BrandLight, dpMap.dp24);
  },
  get BlackBrandLightMixDp26() {
    return mix(this.Black, this.BrandLight, dpMap.dp26);
  },
  get BlackBrandLightMixDp28() {
    return mix(this.Black, this.BrandLight, dpMap.dp28);
  },
  get BlackBrandLightMixDp32() {
    return mix(this.Black, this.BrandLight, dpMap.dp32);
  },
  get BlackBrandLightMixDp40() {
    return mix(this.Black, this.BrandLight, dpMap.dp40);
  },
  get BlackBrandLightMixDp48() {
    return mix(this.Black, this.BrandLight, dpMap.dp48);
  },
  get BlackBrandLight300MixDp1() {
    return mix(this.Black, this.BrandLight300, dpMap.dp1);
  },
  get BlackBrandLight300MixDp2() {
    return mix(this.Black, this.BrandLight300, dpMap.dp2);
  },
  get BlackBrandLight300MixDp3() {
    return mix(this.Black, this.BrandLight300, dpMap.dp3);
  },
  get BlackBrandLight300MixDp4() {
    return mix(this.Black, this.BrandLight300, dpMap.dp4);
  },
  get BlackBrandLight300MixDp6() {
    return mix(this.Black, this.BrandLight300, dpMap.dp6);
  },
  get BlackBrandLight300MixDp8() {
    return mix(this.Black, this.BrandLight300, dpMap.dp8);
  },
  get BlackBrandLight300MixDp12() {
    return mix(this.Black, this.BrandLight300, dpMap.dp12);
  },
  get BlackBrandLight300MixDp16() {
    return mix(this.Black, this.BrandLight300, dpMap.dp16);
  },
  get BlackBrandLight300MixDp24() {
    return mix(this.Black, this.BrandLight300, dpMap.dp24);
  },
  get BlackBrandLight300MixDp26() {
    return mix(this.Black, this.BrandLight300, dpMap.dp26);
  },
  get BlackBrandLight300MixDp28() {
    return mix(this.Black, this.BrandLight300, dpMap.dp28);
  },
  get BlackBrandLight300MixDp32() {
    return mix(this.Black, this.BrandLight300, dpMap.dp32);
  },
  get BlackBrandLight300MixDp40() {
    return mix(this.Black, this.BrandLight300, dpMap.dp40);
  },
  get BlackBrandLight300MixDp48() {
    return mix(this.Black, this.BrandLight300, dpMap.dp48);
  },
  get SurfaceBlackDp1() {
    return fade(this.SurfaceBlack, dpMap.dp1);
  },
  get SurfaceBlackDp2() {
    return fade(this.SurfaceBlack, dpMap.dp2);
  },
  get SurfaceBlackDp3() {
    return fade(this.SurfaceBlack, dpMap.dp3);
  },
  get SurfaceBlackDp4() {
    return fade(this.SurfaceBlack, dpMap.dp4);
  },
  get SurfaceBlackDp6() {
    return fade(this.SurfaceBlack, dpMap.dp6);
  },
  get SurfaceBlackDp8() {
    return fade(this.SurfaceBlack, dpMap.dp8);
  },
  get SurfaceBlackDp12() {
    return fade(this.SurfaceBlack, dpMap.dp12);
  },
  get SurfaceBlackDp16() {
    return fade(this.SurfaceBlack, dpMap.dp16);
  },
  get SurfaceBlackDp24() {
    return fade(this.SurfaceBlack, dpMap.dp24);
  },
  get SurfaceBlackDp26() {
    return fade(this.SurfaceBlack, dpMap.dp26);
  },
  get SurfaceBlackDp28() {
    return fade(this.SurfaceBlack, dpMap.dp28);
  },
  get SurfaceBlackDp32() {
    return fade(this.SurfaceBlack, dpMap.dp32);
  },
  get SurfaceBlackDp40() {
    return fade(this.SurfaceBlack, dpMap.dp40);
  },
  get SurfaceBlackDp48() {
    return fade(this.SurfaceBlack, dpMap.dp48);
  },
  get BrandDp1() {
    return fade(this.Brand, dpMap.dp1);
  },
  get BrandDp2() {
    return fade(this.Brand, dpMap.dp2);
  },
  get BrandDp3() {
    return fade(this.Brand, dpMap.dp3);
  },
  get BrandDp4() {
    return fade(this.Brand, dpMap.dp4);
  },
  get BrandDp6() {
    return fade(this.Brand, dpMap.dp6);
  },
  get BrandDp8() {
    return fade(this.Brand, dpMap.dp8);
  },
  get BrandDp12() {
    return fade(this.Brand, dpMap.dp12);
  },
  get BrandDp16() {
    return fade(this.Brand, dpMap.dp16);
  },
  get BrandDp24() {
    return fade(this.Brand, dpMap.dp24);
  },
  get BrandDp26() {
    return fade(this.Brand, dpMap.dp26);
  },
  get BrandDp28() {
    return fade(this.Brand, dpMap.dp28);
  },
  get BrandDp32() {
    return fade(this.Brand, dpMap.dp32);
  },
  get BrandDp40() {
    return fade(this.Brand, dpMap.dp40);
  },
  get BrandDp48() {
    return fade(this.Brand, dpMap.dp48);
  },
  get BrandLightDp1() {
    return fade(this.BrandLight, dpMap.dp1);
  },
  get BrandLightDp2() {
    return fade(this.BrandLight, dpMap.dp2);
  },
  get BrandLightDp3() {
    return fade(this.BrandLight, dpMap.dp3);
  },
  get BrandLightDp4() {
    return fade(this.BrandLight, dpMap.dp4);
  },
  get BrandLightDp6() {
    return fade(this.BrandLight, dpMap.dp6);
  },
  get BrandLightDp8() {
    return fade(this.BrandLight, dpMap.dp8);
  },
  get BrandLightDp12() {
    return fade(this.BrandLight, dpMap.dp12);
  },
  get BrandLightDp16() {
    return fade(this.BrandLight, dpMap.dp16);
  },
  get BrandLightDp24() {
    return fade(this.BrandLight, dpMap.dp24);
  },
  get BrandLightDp26() {
    return fade(this.BrandLight, dpMap.dp26);
  },
  get BrandLightDp28() {
    return fade(this.BrandLight, dpMap.dp28);
  },
  get BrandLightDp32() {
    return fade(this.BrandLight, dpMap.dp32);
  },
  get BrandLightDp40() {
    return fade(this.BrandLight, dpMap.dp40);
  },
  get BrandLightDp48() {
    return fade(this.BrandLight, dpMap.dp48);
  },
  OnLightSurface: "#1C1B1F",
  GreyMediumLight: "#bebbbe",
  Neutral: "#fff",
  get Neutral90(): string {
    return mix(this.Neutral, this.Black, 90);
  },
  get Neutral60(): string {
    return mix(this.Neutral, this.Black, 60);
  },
  get Neutral45(): string {
    return mix(this.Neutral, this.Black, 45);
  },
  get Neutral30(): string {
    return mix(this.Neutral, this.Black, 30);
  },
  get Neutral90Transparent(): string {
    return fade(this.Neutral, 10);
  },
  get Neutral60Transparent(): string {
    return fade(this.Neutral, 40);
  },
  get Neutral45Transparent(): string {
    return fade(this.Neutral, 55);
  },
  get Neutral30Transparent(): string {
    return fade(this.Neutral, 70);
  },
} as const;

export const getFadedColor = (color: SRColor) => {
  return (
    {
      [SRColors.Brand]: SRColors.Brand200,
      [SRColors.BrandLight]: SRColors.BrandLight200,
      [SRColors.Orange]: SRColors.OrangeFaded,
      [SRColors.Purple]: SRColors.PurpleFaded,
      [SRColors.Pink]: SRColors.PinkFaded,
      [SRColors.Warning]: SRColors.WarningFaded,
      [SRColors.Success]: SRColors.SuccessFaded,
      [SRColors.Error]: SRColors.ErrorFaded,
    }[color] || color
  );
};

export type SRColor = typeof SRColors[keyof typeof SRColors];

export function isSRColor(color?: SRColor): color is SRColor {
  return Object.values(SRColors).includes(color as SRColor);
}
