import classNames from "classnames";
import * as React from "react";
import { Spacing } from "../Spacing";
import styles from "./_Row.module.scss";
import { IRowProps } from "./IRowProps";

export const Row: React.FC<IRowProps> = ({
  children,
  className,
  onClick,
  horizontalAlign = "flex-start",
  verticalAlign = "stretch",
  noGutters = false,
  fullWidth = false,
  noWrap,
  invert,
  style,
  ...spacingProps
}) => (
  <Spacing
    onClick={onClick}
    className={classNames("sr-row", "row", styles.srRow, className, {
      [styles.noGutters]: noGutters,
      "cursor-pointer": onClick,
      [styles.noWrap]: !!noWrap,
      invert,
    })}
    style={{
      ...style,
      ...(typeof invert !== "undefined" ? { flexDirection: invert ? "row-reverse" : "row" } : {}),
      justifyContent: horizontalAlign,
      alignItems: verticalAlign,
    }}
    {...spacingProps}
    fullWidth={fullWidth}
  >
    {children}
  </Spacing>
);

Row.displayName = "Row";
