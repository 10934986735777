import { FC } from "react";
import { SimpleForm, SubmitButton, TextField } from "@serverready-ui/form";
import { IAuthApiRegisterRequest } from "@serverready-ui/api";
import * as yup from "yup";

interface IRegisterFormProps {
  onSubmit: (values: IAuthApiRegisterRequest) => Promise<void>;
}

const registerValidationSchema = yup.object<IAuthApiRegisterRequest>().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});

export const RegisterForm: FC<IRegisterFormProps> = ({ onSubmit }) => {
  return (
    <SimpleForm<IAuthApiRegisterRequest> onSubmit={onSubmit} validationSchema={registerValidationSchema}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <TextField name="email" label="Email" />
          <TextField name="password" label="Password" type="password" />
          <TextField name="firstName" label="First name" />
          <TextField name="lastName" label="Last name" />
          <SubmitButton>Register</SubmitButton>
        </form>
      )}
    </SimpleForm>
  );
};
