import { useContext } from "react";
import { ModalContext } from "../contexts";

export const useModal = () => {
  const modalContext = useContext(ModalContext);
  if (!modalContext) {
    throw new Error("useModal must be used within a ModalContextProvider");
  }
  return modalContext;
};
