import Logger from "js-logger";
import React, { ErrorInfo } from "react";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";

export class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  public componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true });
    // React already logs an error so warn instead of error to prevent duplicate Rollbar errors
    // see https://github.com/facebook/react/issues/15069
    // We are not seeing unexpected errors in rollbar, changing back to error to test
    Logger.error(`Unexpected Error`, error);
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorMessage title="Unexpected Error" />;
    }
    return this.props.children;
  }
}
