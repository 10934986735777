import { AxiosError } from "axios";
import { DefaultApiClient } from "@serverready-ui/api";
import { UserType } from "@serverready-ui/auth";

export class ApiClient extends DefaultApiClient {
  protected async onUnauthorised(error: AxiosError, lastUserType?: UserType) {
    // await super.onUnauthorised(error);

    if (this.options.onAuth) {
      await this.options.onAuth(error);
    }
  }
}
