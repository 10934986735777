import { IApiClient } from "../../IApiClient";
import { IStoreApiStoreGetResponse } from "./IStoreApiResponses";
import { IStoreApiStoreCreateRequest } from "./IStoreApiRequests";

export class StoreApi {
  private client: IApiClient;
  constructor(client: IApiClient) {
    this.client = client;
  }

  public urlForStore(path: string = "") {
    return "/stores" + (path ? "/" + path : "");
  }

  public urlForGet(storeId: string) {
    return this.urlForStore(storeId);
  }

  public urlForCreate() {
    return this.urlForStore("create");
  }

  public async get(storeId: string): Promise<IStoreApiStoreGetResponse> {
    const { data } = await this.client.get<IStoreApiStoreGetResponse>(this.urlForGet(storeId));
    return data;
  }

  public async create(body: IStoreApiStoreCreateRequest): Promise<IStoreApiStoreGetResponse> {
    const { data } = await this.client.post<IStoreApiStoreCreateRequest, IStoreApiStoreGetResponse>(
      this.urlForCreate(),
      body
    );
    return data;
  }
}
