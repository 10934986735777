// ModalContext.tsx
import React, { createContext, FC, PropsWithChildren, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ResponsiveValue,
} from "@chakra-ui/react";
import styles from "./_ModalContext.module.scss";

type ModalSize =
  | ResponsiveValue<"sm" | "md" | "lg" | "xl" | "2xl" | string | "xs" | "3xl" | "4xl" | "5xl" | "6xl" | "full">
  | undefined;

interface ModalData {
  id: string;
  title: string;
  content: React.ReactNode;
  resolve: (value: any) => void;
  size: ModalSize;
}

interface IOpenModalOptions {
  modalId: string;
  modalTitle: string;
  modalContent: React.ReactNode;
  modalSize?: ModalSize;
}
export interface ModalContextProps {
  openModal: <T = any>(options: IOpenModalOptions) => Promise<T>;
  closeModal: (modalId?: any, result?: any) => void;
}

const showWarning = () =>
  console.warn(
    `Trying to call useModal without a valid ModalContext. Make sure you define one by rendering a ModalProvider.`
  );

export const ModalContext = createContext<ModalContextProps>({
  openModal: async <T = void,>() => {
    showWarning();
    return undefined as unknown as T;
  },
  closeModal: showWarning,
});

export const ModalContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [modals, setModals] = useState<ModalData[]>([]);

  const openModal = ({ modalId, modalTitle, modalContent, modalSize }: IOpenModalOptions): Promise<any> => {
    if (!modalSize) {
      modalSize = "md";
    }
    return new Promise(resolve => {
      setModals(prevModals => [
        ...prevModals,
        {
          id: modalId,
          title: modalTitle,
          content: modalContent,
          size: modalSize,
          resolve: resolve,
        },
      ]);
    });
  };

  const closeModal = (modalId?: any, result?: any) => {
    if (!result) {
      result = modalId;
      modalId = undefined;
    }
    setModals(prevModals => {
      if (!modalId) {
        const lastModal = prevModals[prevModals.length - 1];
        lastModal.resolve(result);
        return prevModals.filter((_, index) => index !== prevModals.length - 1);
      }
      const modalIndex = prevModals.findIndex(modal => modal.id === modalId);
      if (modalIndex !== -1) {
        prevModals[modalIndex].resolve(result);
        return prevModals.filter((_, index) => index !== modalIndex);
      }
      return prevModals;
    });
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {modals.map(modal => (
        <Modal size={modal.size} key={modal.id} isOpen={true} onClose={() => closeModal()}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{modal.title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody className={styles.modalBody}>{modal.content}</ModalBody>
          </ModalContent>
        </Modal>
      ))}
    </ModalContext.Provider>
  );
};
