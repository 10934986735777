import * as React from "react";
import { Loading } from "@serverready-ui/design-language/dist/core";
import { ErrorMessage } from "@serverready-ui/design-language/dist/error";
import { IPreloaderProps } from "./IPreloaderProps";

const defaultErrorContent = (error: Error) => {
  return <ErrorMessage message={error.message} title={"Something went wrong"} />;
};

export const Preloader: React.FC<IPreloaderProps> = ({
  children,
  loading,
  error,
  errors,
  errorContent = defaultErrorContent,
  loadingComponent = <Loading />,
}) => {
  if (!error && errors) {
    error = errors[0];
  }

  if (!!error) {
    return <div className="error-message">{errorContent(error)}</div>;
  }
  if (!!loading) {
    return loadingComponent;
  }
  return children ? <>{children()}</> : null;
};

Preloader.displayName = "Preloader";
