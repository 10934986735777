import { FC } from "react";
import { Field } from "react-final-form";

export interface HiddenFieldProps {
  name: string;
  value: any;
  defaultValue?: any;
}

export const HiddenField: FC<HiddenFieldProps> = ({ name, value, defaultValue }) => (
  <Field component="input" type="hidden" name={name} value={value} defaultValue={defaultValue} />
);
