import { IApiClient } from "../../IApiClient";
import { IUserApiProfileResponse } from "./IUserApiResponses";

export class UserApi {
  private client: IApiClient;
  constructor(client: IApiClient) {
    this.client = client;
  }

  public get urlForProfile() {
    return this.urlForUser("profile");
  }

  public urlForUser(path: string) {
    return "/users" + (path ? "/" + path : "");
  }

  public async profile(): Promise<IUserApiProfileResponse> {
    const { data } = await this.client.get<IUserApiProfileResponse>(this.urlForProfile);
    return data;
  }
}
