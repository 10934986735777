import { FC } from "react";
import { Page, SingleColumnLayout, Spacing } from "@serverready-ui/design-language/dist/layout";
import { Header } from "@serverready-ui/design-language/dist/typography";
import { Card, ImageComponent } from "@serverready-ui/design-language/dist/core";
import { IAuthApiLoginRequest, useApiContext } from "@serverready-ui/api";
import { useAuthenticatedUserContext } from "@serverready-ui/auth";
import { JWTStorageService, useExceptionToast } from "@serverready-ui/core";
import logo from "../../../assets/sr/ServerReady_Logo_Slim.png";
import { SignInForm } from "./SignInForm";

export const SignInView: FC = () => {
  const exceptionToast = useExceptionToast();
  const api = useApiContext();
  const { update } = useAuthenticatedUserContext();
  const onSubmit = async (values: IAuthApiLoginRequest) => {
    try {
      await api.Auth.login(values);
      await update?.(JWTStorageService.getAccessToken());
    } catch (error) {
      exceptionToast(error);
    }
  };
  return (
    <Page title="Log In">
      <SingleColumnLayout maxWidth={500}>
        <Spacing verticalPadding="xl">
          <ImageComponent width="300px" alignCenter src={logo} alt="ServerReady Logo" />
        </Spacing>
        <Card padding="md">
          <Header align="center">Log In</Header>
          <SignInForm onSubmit={onSubmit} />
        </Card>
      </SingleColumnLayout>
    </Page>
  );
};
