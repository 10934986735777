import { IProductApiCreateRequest, IProductApiUpdateRequest } from "../IProductApiRequests";
import { IProduct } from "../types";

export const toApiCreate = (product: IProductApiCreateRequest): IProductApiCreateRequest => {
  return {
    ...product,
    price: product.price * 100,
  };
};

export const toApiUpdate = (product: IProductApiUpdateRequest): IProductApiUpdateRequest => {
  return {
    ...product,
    price: product.price ? product.price * 100 : undefined,
  };
};

export const fromApi = (product: IProduct): IProduct => {
  return {
    ...product,
    price: product.price / 100,
  };
};

export const fromApiList = (products: IProduct[]): IProduct[] => {
  return products.map(product => fromApi(product));
};
