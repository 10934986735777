import jwtDecode from "jwt-decode";
import { JWT_TOKEN_LABEL } from "../constants";
import { LocalStorageService } from "./LocalStorageService";

export class JWTStorageService {
  public static getAccessToken(): string | undefined {
    return LocalStorageService.get<string>(JWT_TOKEN_LABEL);
  }

  public static setAccessToken(value: string | undefined) {
    LocalStorageService.set(JWT_TOKEN_LABEL, value);
    window.dispatchEvent(new Event("storage"));
  }

  public static isTokenExpired(): boolean | undefined {
    const token = JWTStorageService.getAccessToken();
    if (!token) {
      // if there isn't a token, then there's no use in trying to refresh it
      return;
    }
    return new Date().getTime() / 1000 > jwtDecode<{ exp: number }>(token).exp;
  }

  public static removeAccessToken(): void {
    LocalStorageService.remove(JWT_TOKEN_LABEL);
    window.dispatchEvent(new Event("storage"));
  }
}
