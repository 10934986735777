import { createContext, useContext } from "react";
import { Api } from "../Api";

// Set initially to undefined to ensure we're creating a new client wherever this is used.
// TODO: if anyone knows how to resolve this null error - can't figure it out
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const ApiContext = createContext<Api>(null);

ApiContext.displayName = "ApiContext";

export const useApiContext = () => {
  return useContext(ApiContext);
};
