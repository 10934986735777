import { FC } from "react";
import { Select } from "@serverready-ui/design-language/dist/input";
import { IServer } from "@serverready-ui/api";
import { useServerContext } from "../contexts";

interface IServerSelectorProps {}

export const ServerSelector: FC<IServerSelectorProps> = ({}) => {
  const serverContext = useServerContext();
  return !!serverContext.servers.length ? (
    <div style={{ minWidth: "240px" }}>
      <Select<IServer>
        name="serverSelector"
        value={serverContext.selectedServer ?? undefined}
        options={serverContext.servers.map(server => ({
          label: server.serverName,
          value: server,
        }))}
        onChange={value => {
          if (value) {
            serverContext.selectServer(value.id);
          }
        }}
      />
    </div>
  ) : null;
};
