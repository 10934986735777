import classNames from "classnames";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/pro-light-svg-icons";
import { faSquareCheck } from "@fortawesome/pro-solid-svg-icons";
import { Column, Row } from "../../layout";
import { Paragraph, Text } from "../../typography";
import { SRColors } from "../../types";
import styles from "./_Checkbox.module.scss";

export interface ICheckboxProps {
  value?: string | number;
  checked?: boolean;
  name: string;
  className?: string;
  id?: string;
  label?: string | React.ReactElement;
  filled?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  readOnly?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent) => void;
}

export const Checkbox: React.FC<ICheckboxProps> = ({
  value,
  checked = false,
  disabled = false,
  label,
  className,
  filled,
  onChange,
  name,
  onClick,
  id,
  readOnly = false,
}) => {
  return (
    <Row
      element="label"
      noWrap
      noGutters
      onClick={onClick}
      verticalAlign="center"
      className={classNames("sr-checkbox", styles.checkbox, className, {
        [styles.checked]: value,
        [styles.disabled]: disabled,
        [styles.filled]: filled,
      })}
    >
      <Column col={!label ? 12 : undefined} noGutters>
        <input
          // Final Form requires an id to work properly
          id={id || `${name}-${value}`}
          name={name}
          type="checkbox"
          disabled={disabled}
          checked={checked}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
        />
        <Text color={SRColors.BrandLight300}>
          <FontAwesomeIcon icon={checked ? faSquareCheck : faSquare} size="2xl" />
        </Text>
      </Column>

      {!!label && (
        <Column noGutters fill>
          <Paragraph className={styles.labelText} weight="semibold" margin="none" marginLeft="sm">
            {label}
          </Paragraph>
        </Column>
      )}
    </Row>
  );
};
