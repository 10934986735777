import { FC, PropsWithChildren } from "react";
import { Row } from "../Row";
import { Column, IColumnProps } from "../Column";

interface ISingleColumnLayoutProps extends Pick<IColumnProps, "className" | "sm" | "md" | "lg" | "col"> {
  maxWidth?: number;
}

export const SingleColumnLayout: FC<PropsWithChildren<ISingleColumnLayoutProps>> = ({
  children,
  className,
  sm = 8,
  md,
  lg = 5,
  col = 11,
  maxWidth,
}) => {
  return (
    <Row horizontalAlign="center">
      <Column
        sm={sm}
        md={md}
        lg={lg}
        col={col}
        style={{
          maxWidth: maxWidth ? `${maxWidth}px` : undefined,
        }}
      >
        {children}
      </Column>
    </Row>
  );
};
