import { ChangeEvent, DragEvent, FC, ReactElement, useState } from "react";
import { makeImageUrl, useExceptionToast } from "@serverready-ui/core";
import { useDropArea } from "react-use";
import { AbsoluteCenter } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenCircle, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { Position, Spacing } from "../../layout";
import { Card, ImageComponent, Loading } from "../../core";
import { FormFieldLabel } from "../FormFieldLabel/FormFieldLabel";
import { Paragraph } from "../../typography";
import { SRColors } from "../../types";

export interface IFileUploadProps {
  currentId?: string;
  value?: FileList | string;
  name: string;
  label?: string | ReactElement;
  className?: string;
  disabled?: boolean;
  onChange?: (files: FileList) => Error | void;
  submitting?: boolean;
  accept?: string;
  extensions?: string;
  /**
   * Maximum file size in Megabytes
   */
  maxFileSize?: number;
}

export const FileUpload: FC<IFileUploadProps> = ({
  name,
  currentId,
  className,
  disabled,
  /**
   * Mainly for any validation that needs to be done before uploading the file
   *  Should throw an error if the file is invalid
   */
  onChange,
  label,
  submitting,
  accept,
  extensions,
  maxFileSize,
}) => {
  const [currentFile, setCurrentFile] = useState<string | null>(null);
  const [bond, state] = useDropArea({
    onFiles: async (files: File[], event: ChangeEvent<HTMLInputElement>) => {
      await handleFileChange(event, files as unknown as FileList);
    },
  });
  function onDragLeave(event: DragEvent<HTMLElement>) {
    if (!(event.relatedTarget instanceof Node)) {
      return;
    }
    if (event.currentTarget.contains(event.relatedTarget)) {
      return;
    }
    bond.onDragLeave(event);
  }

  const exceptionToast = useExceptionToast();

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>, files?: FileList) => {
    try {
      const targetFiles = files || (event.target.files && event.target.files);
      console.log("targetFiles", targetFiles);
      if (targetFiles) {
        onChange?.(targetFiles);
        setCurrentFile(URL.createObjectURL(targetFiles[0]));
      }
    } catch (error) {
      exceptionToast(error, {
        title: "Error uploading file",
      });
    }
  };

  const imageSrc = currentFile || (currentId ? makeImageUrl(currentId) : undefined);
  return (
    <Spacing className={className}>
      <FormFieldLabel
        name={name}
        label={label}
        labelAppend={
          <div {...bond} onDragLeave={onDragLeave}>
            <Card
              padding="md"
              marginTop="xs"
              border
              style={{ borderStyle: state.over ? "solid" : "dashed", position: "relative" }}
            >
              {!!imageSrc && (
                <div style={{ position: "relative" }}>
                  <ImageComponent rounded alignCenter src={imageSrc} alt="Product image" width="165px" />
                  <Position top={-8} right={-8}>
                    <FontAwesomeIcon icon={faPenCircle} size="xl" color={SRColors.BrandLight300} />
                  </Position>
                </div>
              )}
              {!imageSrc && (
                <Card
                  style={{
                    margin: "0 auto",
                    width: "165px",
                    height: "165px",
                  }}
                >
                  <AbsoluteCenter>
                    <FontAwesomeIcon size="2xl" icon={faPlus} />
                  </AbsoluteCenter>
                </Card>
              )}
              {!!extensions && (
                <Paragraph marginBottom="none" align="center">
                  Allowed file types: {extensions}
                </Paragraph>
              )}
              {!!maxFileSize && (
                <Paragraph marginBottom="none" align="center">
                  Max file size: {maxFileSize}MB
                </Paragraph>
              )}
              {submitting && (
                <Position
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  style={{
                    background: SRColors.Neutral90Transparent,
                  }}
                >
                  <AbsoluteCenter>
                    <Loading />
                  </AbsoluteCenter>
                </Position>
              )}
            </Card>
          </div>
        }
      />
      <input
        type="file"
        id={name}
        name={name}
        disabled={disabled}
        accept={accept}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </Spacing>
  );
};
