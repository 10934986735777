import Logger from "js-logger";
import React, { useEffect } from "react";
import { ErrorMessage } from "@serverready-ui/design-language/dist/error";
import { useLocation as useLocationWithoutRouter } from "react-use";

export const NotFoundContent: React.FC = () => {
  const { pathname, trigger } = useLocationWithoutRouter();
  useEffect(() => {
    Logger.error("404", pathname);
  }, [pathname, trigger]);
  return <ErrorMessage title="Page not found!" message="Sorry, we can't seem to find the page you're looking for." />;
};
