import { ValidationError } from "yup";
import { set } from "lodash";

const parseError = (key: string | undefined, error: string) => {
  if (key && error.startsWith(key)) {
    // convert camelCase to Camel case
    return error.replace(/([A-Z])/g, " $1").replace(/^./, str => str.toUpperCase());
  }
  return error;
};

export const mapYupErrorToKeyValue = (err: ValidationError): { [key: string]: string } => {
  const errors: { [key: string]: string } = {};
  if (err.path) {
    errors[err.path] = err.message;
  }

  if (!err.inner) {
    throw err;
  }

  return err.inner.reduce((result: { [key: string]: string }, error: ValidationError) => {
    return set(result, error.path!, parseError(error.path, error.message));
  }, errors);
};
