import React from "react";
import { Api, ApiContext } from "@serverready-ui/api";
import { getEnvironment } from "@serverready-ui/core";
import { ModalContextProvider, Preloader } from "@serverready-ui/components";
import { ChakraBaseProvider, extendBaseTheme } from "@chakra-ui/react";
import { AuthenticatedUserContext, UserType } from "@serverready-ui/auth";
import { useAuthenticatedUser } from "@serverready-ui/auth/dist/context/useAuthenticatedUser";
import { AxiosError } from "axios";
import chakraTheme from "@chakra-ui/theme";
import { SRColors } from "@serverready-ui/design-language/dist/types";
import { ApiClient } from "./services/ApiClient";
import { ApplicationRouter } from "./ApplicationRouter";

interface IAppProps {}

const { Modal, Alert } = chakraTheme.components;
const theme = extendBaseTheme({
  styles: {
    global: () => ({
      body: {
        bg: "",
        color: "",
        fontFamily: "",
      },
    }),
  },
  colors: {
    brand: {
      100: SRColors.Brand100,
      200: SRColors.Brand200,
      300: SRColors.Brand300,
      400: SRColors.Brand400,
      500: SRColors.Brand500,
      600: SRColors.Brand600,
      700: SRColors.Brand700,
      800: SRColors.Brand,
      900: SRColors.Brand900,
    },
  },
  components: {
    Modal,
    Alert,
  },
});

export const App: React.FC<IAppProps> = () => {
  const api = new Api(getEnvironment().generateApiRoute(), ApiClient, {
    onAuth: async (error: AxiosError, lastUserType?: UserType) => {
      // TODO: Handle what to do on unauthorised
      //  i.e. show a modal, redirect to login, reload, etc.
    },
  });
  // TODO:
  //  <ToastProvider>

  const [data, meta] = useAuthenticatedUser(api);
  return (
    <ApiContext.Provider value={api}>
      <ChakraBaseProvider
        theme={theme}
        toastOptions={{
          defaultOptions: {
            position: "bottom-right",
          },
        }}
      >
        <ModalContextProvider>
          <Preloader {...meta}>
            {() => {
              return (
                <AuthenticatedUserContext.Provider value={{ ...data }}>
                  <ApplicationRouter />
                </AuthenticatedUserContext.Provider>
              );
            }}
          </Preloader>
        </ModalContextProvider>
      </ChakraBaseProvider>
    </ApiContext.Provider>
  );
};
