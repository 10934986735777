import { EnvironmentName } from "./types/EnvironmentName";

interface Config {
  debug: boolean;
  apiBase: string;
  appBasePath: string;
  appDomain: string;
  cookieDomain: string;
}

export class Environment {
  public readonly appDomain: string;
  public readonly cookieDomain: string;
  public readonly name: EnvironmentName;
  // Use helper functions to generate paths
  protected readonly apiBase: string;
  protected readonly appBasePath: string;
  private readonly debug: boolean;

  constructor(name: EnvironmentName, config: Config) {
    this.name = name;
    this.debug = config.debug;
    this.apiBase = config.apiBase;
    this.appBasePath = config.appBasePath;
    this.appDomain = config.appDomain;
    this.cookieDomain = config.cookieDomain;
  }

  public get isProduction() {
    return this.name === "master";
  }

  public get isDebug() {
    return this.debug;
  }

  public generateApiRoute(pathname: string = "/") {
    this.validatePath(pathname);
    return this.apiBase + pathname;
  }

  public generateAppRoute(pathname: string = "/") {
    return this.generateRoute(this.appBasePath, pathname);
  }

  private generateRoute(basePath: string, path: string): string {
    this.validatePath(path);
    const url = new URL(path, basePath);
    return url.toString();
  }

  private validatePath(pathname: string): never | void {
    if (!pathname.startsWith("/")) {
      throw new Error(`Attempt to generate path from '${pathname}' failed, make sure it starts with a '/'`);
    }
  }
}
