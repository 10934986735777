import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { NotUndefined } from "@serverready-ui/core";
import { faBoxOpenFull, faHome, faShapes, faStore } from "@fortawesome/pro-duotone-svg-icons";
import { IAppLinkProps } from "../AppLink/AppLink";
import { storeLocations } from "../../routes";

interface SideBarLink extends IAppLinkProps {
  icon: IconProp;
  label: string;
}

export const sideBarLinks = (storeId?: string): SideBarLink[] =>
  [
    {
      to: storeLocations.Dashboard.buildPath({}),
      label: "Dashboard",
      icon: faHome,
      exact: true,
    },
    ...(storeId
      ? [
          {
            to: storeLocations.View.buildPath({}),
            label: "Store",
            icon: faStore,
          },
          {
            to: storeLocations.Collections.buildPath({}),
            label: "Collections",
            icon: faBoxOpenFull,
          },
          {
            to: storeLocations.Products.buildPath({}),
            label: "Products",
            icon: faShapes,
          },
        ]
      : []),
  ].filter(NotUndefined) as SideBarLink[];
