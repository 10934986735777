import classNames from "classnames";
import React, { PropsWithChildren } from "react";

import { Span } from "../../typography";
import { SRColor, SRColors, TypographyFontSize, TypographyWeight } from "../../types";
import { isColor } from "../../utils/color/isColor";
import { Column, ISpacingProps, Row, Spacing } from "../../layout";
import styles from "./_Pill.module.scss";

type PillCornerStyle = "round" | "square";

export interface PillProps {
  size?: TypographyFontSize;
  color?: SRColor;
  fullWidth?: boolean;
  cornerStyle?: PillCornerStyle;
  className?: string;
  weight?: TypographyWeight;
  border?: boolean;
  borderColor?: SRColor;
  capitalize?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  textColor?: SRColor;
  noWrap?: boolean;
  spacing?: ISpacingProps;
  // TODO: icons
}

export const Pill: React.FC<PropsWithChildren<PillProps>> = ({
  children,
  fullWidth = false,
  size = "sm",
  color = "grey",
  cornerStyle = "round",
  className,
  weight = "regular",
  border = false,
  borderColor,
  capitalize = false,
  onClick,
  textColor = color === "darkgrey" ? SRColors.Neutral : undefined,
  noWrap,
  spacing,
}) => {
  const iconSize =
    {
      tn: "sm",
      xs: "sm",
      xl: "lg",
      jm: "lg",
    }[size] || size;

  return (
    <Spacing
      onClick={onClick}
      className={classNames(styles.pill, "sr-pill", className, {
        [styles.cornerRound]: cornerStyle === "round",
        [styles.cornerSquare]: cornerStyle === "square",
        [styles.sizeTn]: size === "tn",
        [styles.sizeXs]: size === "xs",
        [styles.sizeSm]: size === "sm",
        [styles.sizeMd]: size === "md",
        [styles.sizeLg]: size === "lg",
        [styles.fullWidth]: fullWidth,
        [styles.noWrap]: noWrap,
        [styles.clickable]: !!onClick,
        [styles.border]: border,
      })}
      style={{
        background: isColor(color) ? color : undefined,
        ...(borderColor && isColor(borderColor) ? { borderColor: borderColor } : {}),
      }}
      fullWidth={false}
      {...spacing}
    >
      <Span
        size={size}
        margin="none"
        weight={weight}
        transform={capitalize ? "capitalize" : undefined}
        color={textColor}
      >
        <Row noGutters verticalAlign="center" horizontalAlign="center" noWrap>
          <Column noGutters>{children}</Column>
        </Row>
      </Span>
    </Spacing>
  );
};

Pill.displayName = "Pill";
