import React, { FC, PropsWithChildren } from "react";
import { NavigationBar } from "@serverready-ui/design-language/dist/navigation";
import { useAuthenticatedUserContext } from "@serverready-ui/auth";
import { Column, Row } from "@serverready-ui/design-language/dist/layout";
import { Paragraph } from "@serverready-ui/design-language/dist/typography";
import { ServerSelector } from "@serverready-ui/components";
import { AppLink } from "../AppLink/AppLink";
import { navigationBarLinks } from "./navigationBarLinks";

export const AppNavigationBar: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuthenticatedUserContext();
  return (
    <NavigationBar>
      <Row verticalAlign="center" horizontalAlign="space-between">
        <Column shrink={1} grow={0} col={3}>
          <ServerSelector />
        </Column>
        <Column>{children}</Column>
        <Column>
          <Row verticalAlign="center" horizontalAlign="flex-end">
            <Column shrink={1} grow={0}>
              <Paragraph>{user?.firstName}</Paragraph>
            </Column>
            <Column shrink={1} grow={0}>
              {navigationBarLinks.map(link => (
                <AppLink key={link.id || link.to.toString()} to={link.to} horizontalMargin="sm" textDecoration="none">
                  {link.label}
                </AppLink>
              ))}
            </Column>
          </Row>
        </Column>
      </Row>
    </NavigationBar>
  );
};
