import { FC, PropsWithChildren } from "react";
import { FileUpload, IFileUploadProps } from "@serverready-ui/design-language/dist/input";
import { IFormField } from "@serverready-ui/design-language/dist/types";
import { FormField } from "../FormField/FormField";

type AllowedFileTypes = "png" | "gif" | "jpeg" | "webp" | "svg";

const fileTypeToMimeType = (fileType: AllowedFileTypes): string => {
  switch (fileType) {
    case "png":
      return "image/png";
    case "gif":
      return "image/gif";
    case "jpeg":
      return "image/jpeg";
    case "webp":
      return "image/webp";
    case "svg":
      return "image/svg+xml";
  }
};

const mapAllowedFileTypesToMimeTypes = (allowedFileTypes: AllowedFileTypes[]): string => {
  return allowedFileTypes.map(fileTypeToMimeType).join(",");
};

// accept is handled by the allowedFileTypes prop
// onUpload is handled by this component
export interface IFileUploadFieldProps extends IFormField, Omit<IFileUploadProps, "accept"> {
  defaultValue?: boolean;
  allowedFileTypes?: AllowedFileTypes[];
  /**
   * Maximum file size in Megabytes
   */
  maxFileSize?: number;
}

export const ImageUploadField: FC<PropsWithChildren<IFileUploadFieldProps>> = ({
  name,
  id,
  className,
  label,
  disabled,
  explainer,
  hidden,
  children,
  value,
  currentId,
  labelSize,
  marginBottom,
  defaultValue,
  onChange,
  maxFileSize = 10,
  allowedFileTypes = ["png", "gif", "jpeg", "webp", "svg"],
  ...restProps
}) => {
  /**
   * @throws {Error}
   * @returns {void}
   */
  const validateFile = (files: FileList) => {
    if (!files.length) {
      return;
    }
    const file = files[0];
    if (file.size > maxFileSize * 1024 * 1024) {
      throw new Error(`File size is too large. Maximum file size is ${maxFileSize}MB.`);
    }
    if (!allowedFileTypes.map(fileTypeToMimeType).includes(file.type)) {
      throw new Error(`File type is not supported. Supported file types are ${allowedFileTypes.join(", ")}.`);
    }
  };
  return (
    <FormField
      name={name}
      disabled={disabled}
      explainer={explainer}
      hidden={hidden}
      type="file"
      value={value}
      marginBottom={marginBottom}
      defaultValue={defaultValue}
    >
      {({ input }) => (
        <>
          {children}
          <FileUpload
            label={label}
            currentId={currentId}
            className={className}
            disabled={disabled}
            accept={mapAllowedFileTypesToMimeTypes(allowedFileTypes)}
            extensions={allowedFileTypes.join(", ")}
            maxFileSize={maxFileSize}
            {...restProps}
            {...input}
            onChange={files => {
              validateFile(files);
              input.onChange(files);
            }}
          />
        </>
      )}
    </FormField>
  );
};
