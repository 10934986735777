import { IApiClient } from "../../IApiClient";
import { IImageApiRequestImageTokenResponse } from "./IImageApiResponses";

export class ImageApi {
  private client: IApiClient;
  constructor(client: IApiClient) {
    this.client = client;
  }

  public get urlForRequestImageToken() {
    return this.urlForImage("/request-image-token");
  }

  public async requestImageToken(): Promise<IImageApiRequestImageTokenResponse> {
    const { data } = await this.client.get<IImageApiRequestImageTokenResponse>(this.urlForRequestImageToken);
    return data;
  }

  private urlForImage(path: string) {
    return "/images" + path;
  }
}
