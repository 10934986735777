import { Button } from "@serverready-ui/design-language/dist/core";
import { ITableButtonProps } from "./types";

export const TableButton = <T,>({ onClick, ...props }: ITableButtonProps<T>) => {
  return (
    <Button
      size="xs"
      onClick={event => {
        onClick?.(event, props.data);
      }}
    >
      {props.value}
    </Button>
  );
};
