import { FC, PropsWithChildren } from "react";
import { IPageProps, Page } from "@serverready-ui/design-language/dist/layout";
import { AppNavigationBar } from "../AppNavigationBar/AppNavigationBar";

export const AppPage: FC<PropsWithChildren<IPageProps>> = props => {
  const { children, ...pageProps } = props;
  return (
    <Page {...pageProps} hasHeader hasSidebar>
      <AppNavigationBar />
      {children}
    </Page>
  );
};
