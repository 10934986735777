import classNames from "classnames";
import React, { PropsWithChildren } from "react";

import {
  getFadedColor,
  SizeJumbo,
  SizeLarge,
  SizeMedium,
  SizeSmall,
  SizeXLarge,
  SizeXXLarge,
  SRColor,
  SRColors,
} from "../../types";
import { Span } from "../../typography";
import { Spacing } from "../../layout";
import styles from "./_Avatar.module.scss";

export type AvatarSize = SizeSmall | SizeMedium | SizeLarge | SizeXLarge | SizeXXLarge | SizeJumbo;
type AllowedColors = "Brand" | "BrandLight" | "Success" | "Warning" | "Error";

export interface IAvatarProps {
  // make name required to at least have initials to fallback on if no image
  name: string;
  shape?: "circle" | "square";
  size?: AvatarSize;
  image?: string;
  color?: typeof SRColors[AllowedColors];
  className?: string;
  contain?: boolean;
  center?: boolean;
  // Trim initials, if you don't want to trim use Infinity - default is 4
  maxInitials?: number;
  outline?: boolean;
}

const sizeMap = {
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "h2",
  "2x": "h1",
  jm: "title",
} as const;

const getInitialString = (value: string) => {
  if (!value) {
    return "";
  }
  const result = value.match(/\b\w(?!nd)/g) || ["U"]; // default to "U" if somehow no initials
  return result.join("").toUpperCase();
};

const getAvatarFallbackColor = (name: string): SRColor => {
  const alphabetGroups = ["ABCD", "EFGH", "IJKL", "MNOP", "QRST", "UVWXYZ"];
  const nameFirstLetter = name.charAt(0).toUpperCase();
  const groupIndex = alphabetGroups.findIndex(group => group.includes(nameFirstLetter));
  return (
    [SRColors.Brand, SRColors.BrandLight, SRColors.Success, SRColors.Warning, SRColors.Error][groupIndex] ||
    SRColors.Brand
  );
};

export const Avatar: React.FC<PropsWithChildren<IAvatarProps>> = ({
  name,
  image,
  color,
  shape = "circle",
  size = "md",
  contain,
  className,
  center,
  maxInitials = 4,
  outline,
  children,
}) => {
  const chosenColor = color || (image ? SRColors.Neutral : getAvatarFallbackColor(name));

  return (
    <Spacing className="position-relative">
      <div
        style={{
          backgroundImage: `url(${image})`,
          backgroundColor: getFadedColor(chosenColor),
          // use the same solid chosen colour if outline is required
          // not really necessary to pass in a specific colour for outline for now
          outline: outline ? `2px solid ${chosenColor}` : undefined,
          outlineOffset: outline ? "4px" : undefined,
          boxShadow: `rgba(0, 0, 0, 0.2) 0px 0px 0px 1px inset`,
        }}
        className={classNames(styles.avatar, className, {
          [styles.sizeSm]: size === "sm",
          [styles.sizeMd]: size === "md",
          [styles.sizeLg]: size === "lg",
          [styles.sizeXl]: size === "xl",
          [styles.size2x]: size === "2x",
          [styles.sizeJm]: size === "jm",
          [styles.shapeCircle]: shape === "circle",
          [styles.shapeSquare]: shape === "square",
          [styles.hasImage]: !!image,
          [styles.contain]: contain,
          [styles.center]: center,
        })}
      >
        <Span className={styles.initials} color={chosenColor} size={sizeMap[size]}>
          {/* TODO: icon as fallback rather than ""? */}
          {!image ? (!!name ? getInitialString(name).slice(0, maxInitials) : "") : null}
        </Span>
      </div>

      {children}
    </Spacing>
  );
};

Avatar.displayName = "Avatar";
