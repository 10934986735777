import _ from "lodash";
import React, { FC } from "react";
import Textarea from "react-textarea-autosize";
import classNames from "classnames";
import styles from "./_Input.module.scss";
import { InputType } from "./InputType";

export interface InputProps {
  className?: string;
  // Whether or not the field is disabled
  disabled?: boolean;
  // Whether or not the form field is valid
  invalid?: boolean;
  // The size of the field, can be medium or large
  size?: "small" | "medium" | "large";
  // The localtype type of the field
  type?: InputType;
  // The placeholder value to display before input
  placeholder?: string;
  autoFocus?: boolean;
  maxLength?: number;
  minRows?: number;
  maxRows?: number;
  min?: number; // used for number inputs only
  max?: number; // used for number inputs only
  name: string;
  value?: string;
  autocomplete?: string; // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete

  onChange?: (value: string) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
}

export const Input: FC<InputProps> = ({
  className,
  disabled = false,
  invalid = false,
  size = "small",
  type = "text",
  placeholder,
  autoFocus,
  maxLength,
  minRows,
  maxRows,
  min,
  max,
  name,
  value,
  autocomplete,
  onChange,
  onFocus,
  onBlur,
  onPaste,
}) => {
  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = _.get(event, "target.value", "");
    if (onChange) {
      onChange(newValue);
    }
  };

  const _onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(event);
    }
  };

  const _onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(event);
    }
  };

  // We want to ensure that a value is always set to avoid bugs with passing undefined values
  // ref: https://github.com/facebook/react/issues/6222
  const newValue = value || "";
  const classes = classNames("sr-input", styles.input, className, {
    [styles.invalid]: invalid,
    [styles.sizeSmall]: size === "small",
    [styles.sizeLarge]: size === "large",
  });
  switch (type) {
    case "textarea":
      return (
        <div className={classes}>
          <Textarea
            name={name}
            id={name}
            placeholder={placeholder}
            onChange={handleValueChange}
            value={newValue}
            autoFocus={autoFocus}
            disabled={disabled}
            minRows={minRows}
            maxRows={maxRows}
            autoComplete={autocomplete}
          />
        </div>
      );
    default:
      return (
        <div className={classes}>
          <input
            name={name}
            id={name}
            type={type}
            onChange={handleValueChange}
            value={newValue}
            placeholder={placeholder}
            autoFocus={autoFocus}
            onPaste={onPaste}
            disabled={disabled}
            maxLength={maxLength}
            onBlur={_onBlur}
            onFocus={_onFocus}
            autoComplete={autocomplete}
            min={min}
            max={max}
          />
        </div>
      );
  }
};
