import { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { IServer, useApiContext } from "@serverready-ui/api";
import { assertDefined, LocalStorageService, SELECTED_SERVER_KEY, useImmutableSWRService } from "@serverready-ui/core";
import { ErrorMessage } from "@serverready-ui/design-language/dist/error";
import { Preloader } from "../hooks";

interface ServerContextType {
  servers: IServer[];
  selectedServer: IServer | null;
  selectServer: (server: IServer["id"]) => void;
  refreshServerList: () => void;
}

interface DefinedServerContextType extends ServerContextType {
  selectedServer: IServer;
}

export const useServerContext = (): ServerContextType => {
  return useContext(ServerContext);
};
/**
 * This hook is used to assert that the selectedServer is defined.
 * Currently only useful for when in a RequiredStorePage already, which requires a server to be selected to work.
 */
export const useDefinedServerContext = (): DefinedServerContextType => {
  const context = useContext(ServerContext);
  const { selectedServer } = context;
  assertDefined(selectedServer, "selectedServer is unexpectedly undefined.");
  return context as DefinedServerContextType;
};
const defaultServerContext: ServerContextType = {
  servers: [],
  selectedServer: null,
  selectServer: () => {
    // intentionally left blank due to eslint rules
  },
  refreshServerList: () => {
    // intentionally left blank due to eslint rules
  },
};

export const ServerContext = createContext<ServerContextType>(defaultServerContext);

export const ServerContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { Server } = useApiContext();
  const [data, meta, refresh] = useImmutableSWRService(Server.urlForList, () => Server.list());

  const getServer = useCallback(
    (serverId?: IServer["id"]) => {
      const defaultServer = data?.[0] || null;
      return data?.find(server => server.id === serverId) || defaultServer;
    },
    [data]
  );

  const [selectedServer, setSelectedServer] = useState<IServer | null>(() =>
    getServer(LocalStorageService.get<IServer["id"]>(SELECTED_SERVER_KEY))
  );

  const selectServer = useCallback(
    (serverId: IServer["id"]) => {
      setSelectedServer(getServer(serverId));
      LocalStorageService.set<IServer["id"]>(SELECTED_SERVER_KEY, serverId);
    },
    [getServer]
  );

  useEffect(() => {
    const storedServer = LocalStorageService.get<IServer["id"]>(SELECTED_SERVER_KEY);
    if (data) {
      selectServer(storedServer || data[0]?.id);
    }
  }, [data, selectServer]);

  if (meta.error) {
    return <ErrorMessage title={meta.error.message} />;
  }

  return (
    <Preloader {...meta}>
      {() => {
        assertDefined(data, "data");
        return (
          <ServerContext.Provider
            value={{
              servers: data,
              selectedServer,
              selectServer,
              refreshServerList: refresh,
            }}
          >
            {children}
          </ServerContext.Provider>
        );
      }}
    </Preloader>
  );
};
