import { IApiClient } from "../../IApiClient";
import {
  IServerApiServerCreateResponse,
  IServerApiServerEditResponse,
  IServerApiServerListResponse,
} from "./IServerApiResponses";
import { IServerApiCreateRequest, IServerApiEditRequest } from "./IServerApiRequests";
import { fromApi } from "./transform";

export class ServerApi {
  private client: IApiClient;
  constructor(client: IApiClient) {
    this.client = client;
  }

  public get urlForList() {
    return this.urlForServer("/list");
  }

  public get urlForCreate() {
    return this.urlForServer("/create");
  }

  public get urlForEdit() {
    return this.urlForServer("/edit");
  }

  public async list(): Promise<IServerApiServerListResponse> {
    const { data } = await this.client.get<IServerApiServerListResponse>(this.urlForList);
    return data;
  }

  public async create(body: IServerApiCreateRequest) {
    const { data } = await this.client.post<IServerApiCreateRequest, IServerApiServerCreateResponse>(
      this.urlForCreate,
      body
    );
    return fromApi(data);
  }

  public async edit(body: IServerApiEditRequest) {
    const { data } = await this.client.patch<IServerApiEditRequest, IServerApiServerEditResponse>(
      this.urlForEdit,
      body
    );
    return fromApi(data);
  }

  private urlForServer(path: string) {
    return "/servers" + path;
  }
}
