import { NavLink, To } from "react-router-dom";
import { FC, PropsWithChildren } from "react";
import { ISpanProps, Span } from "@serverready-ui/design-language/dist/typography";
import { SRColors } from "@serverready-ui/design-language/dist/types";
import classNames from "classnames";
import buttonStyles from "@serverready-ui/design-language/dist/core/Button/_Button.module.scss";
import { ButtonSize, ButtonStatus, ButtonType } from "@serverready-ui/design-language/dist/core/Button/types";
import styles from "./_AppLink.module.scss";

export interface IAppLinkProps extends ISpanProps {
  to: To;
  exact?: boolean;
  buttonType?: ButtonType;
  buttonStatus?: ButtonStatus;
  buttonSize?: ButtonSize;
}

export const AppLink: FC<PropsWithChildren<IAppLinkProps>> = ({
  to,
  buttonType,
  buttonStatus,
  buttonSize,
  children,
  className,
  exact,
  ...spanProps
}) => {
  const isButton = buttonType || buttonStatus || buttonSize;
  return (
    <NavLink to={to} className={classNames(styles.link, className)} end={exact}>
      <Span
        color={!isButton ? SRColors.BrandLight100 : undefined}
        size="sm"
        weight="light"
        className={classNames(styles.span, {
          [buttonStyles.button]: isButton,
          [buttonStyles.typeFilled]: buttonType === "filled",
          [buttonStyles.typeOutline]: buttonType === "outline",
          [buttonStyles.typeTonal]: buttonType === "tonal",
          [buttonStyles.typeText]: buttonType === "text",
          [buttonStyles.statusSuccess]: buttonStatus === "success",
          [buttonStyles.statusError]: buttonStatus === "error",
          [buttonStyles.statusWarning]: buttonStatus === "warning",
          [buttonStyles.sizeXs]: buttonSize === "xs",
          [buttonStyles.sizeSm]: buttonSize === "sm",
          [buttonStyles.sizeLg]: buttonSize === "lg",
        })}
        {...spanProps}
      >
        {children}
      </Span>
    </NavLink>
  );
};
