import Logger from "js-logger";
import { Develop } from "./Develop";
import { Environment } from "./Environment";
import { Local } from "./Local";
import { Master } from "./Master";
import { EnvironmentName } from "./types";

export class EnvironmentConfigurer {
  private static environment: Environment;

  public static getEnvironment = (): Environment => {
    if (!EnvironmentConfigurer.environment) {
      const stage = process.env.REACT_APP_STAGE || process.env.STAGE;
      console.log("stage", stage);
      console.log("process.env", process.env);
      const message = "There is no process.env.STAGE or process.env.REACT_APP_STAGE set.";
      if (!stage) {
        if (typeof window !== "undefined" && window.location.hostname.includes("localhost")) {
          throw new Error(message);
        } else {
          Logger.error(message + "Returning master configuration.");
          EnvironmentConfigurer.configureByStage("master");
        }
      } else {
        EnvironmentConfigurer.configureByStage((stage as EnvironmentName) || "master");
      }
    }

    return EnvironmentConfigurer.environment;
  };

  public static configureByStage = (stage: EnvironmentName): void => {
    if (EnvironmentConfigurer.environment && EnvironmentConfigurer.environment.name !== stage) {
      Logger.warn(
        `Environment was configured as ${EnvironmentConfigurer.environment.name} and is being re-configured as ${stage}`
      );
    }
    switch (stage) {
      case "local":
        EnvironmentConfigurer.setEnvironment(Local);
        return;

      case "develop":
        EnvironmentConfigurer.setEnvironment(Develop);
        return;

      default:
        EnvironmentConfigurer.setEnvironment(Master);
        return;
    }
  };

  private static setEnvironment = (environment: Environment): void => {
    EnvironmentConfigurer.environment = environment;
  };
}
