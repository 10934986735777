import { IApiClient } from "../../IApiClient";
import { ICollectionApiCollectionListResponse } from "./ICollectionApiResponses";
import { ICollection } from "./types";
import {
  ICollectionApiCreateRequest,
  ICollectionApiDeleteRequest,
  ICollectionApiUpdateRequest,
} from "./ICollectionApiRequests";

export class CollectionApi {
  private client: IApiClient;
  constructor(client: IApiClient) {
    this.client = client;
  }

  public urlForList(storeId: string) {
    return this.urlForCollection(storeId);
  }

  public urlForCreate(storeId: string) {
    return this.urlForCollection(`create/${storeId}`);
  }

  public urlForUpdate(storeId: string, collectionId: string) {
    return this.urlForCollection(`update/${storeId}/${collectionId}`);
  }

  public urlForDelete(storeId: string) {
    return this.urlForCollection(`delete/${storeId}`);
  }

  public async list(storeId: string): Promise<ICollectionApiCollectionListResponse> {
    const { data } = await this.client.get<ICollectionApiCollectionListResponse>(this.urlForList(storeId));
    return data;
  }

  public async create(storeId: string, body: ICollectionApiCreateRequest): Promise<ICollection> {
    const { data } = await this.client.post<ICollectionApiCreateRequest, ICollection>(this.urlForCreate(storeId), body);
    return data;
  }

  public async update(storeId: string, collectionId: string, body: ICollectionApiUpdateRequest): Promise<ICollection> {
    const { data } = await this.client.patch<ICollectionApiUpdateRequest, ICollection>(
      this.urlForUpdate(storeId, collectionId),
      body
    );
    return data;
  }

  public async delete(storeId: string, body: ICollectionApiDeleteRequest): Promise<void> {
    await this.client.post(this.urlForDelete(storeId), body);
  }

  private urlForCollection(path: string = "") {
    return "/collections" + (path ? "/" + path : "");
  }
}
