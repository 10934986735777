import { CSSProperties, FC, MouseEvent, PropsWithChildren } from "react";
import classNames from "classnames";
import styles from "./_ImageComponent.module.scss";

export interface IImageComponentProps {
  children?: undefined; // Make sure no children can be passed
  className?: string;
  width?: string;
  height?: string;
  alignCenter?: boolean;
  block?: boolean;
  rounded?: boolean;
  style?: CSSProperties;

  // img props, we can add more as we need them
  // nice to haves: srcSet
  src: string;
  alt: string;
  onClick?: (event: MouseEvent<HTMLImageElement>) => void;
}

export const ImageComponent: FC<PropsWithChildren<IImageComponentProps>> = ({
  children,
  className,
  width,
  height,
  alignCenter = false,
  block,
  style,
  src,
  alt,
  rounded,
  ...imageProps
}) => {
  return (
    <img
      alt={alt}
      className={classNames("sr-image", className, {
        [styles.alignCenter]: alignCenter,
        [styles.displayBlock]: block,
        [styles.rounded]: rounded,
        [styles.clickable]: !!imageProps.onClick,
      })}
      style={{ width, height, ...style }}
      src={src}
      {...imageProps}
    />
  );
};

ImageComponent.displayName = "ImageComponent";
