import { FC } from "react";
import { CheckboxField, SimpleForm, SubmitButton, TextField } from "@serverready-ui/form";
import { IAuthApiLoginRequest } from "@serverready-ui/api";
import * as yup from "yup";

interface IRegisterFormProps {
  onSubmit: (values: IAuthApiLoginRequest) => Promise<void>;
}

const signInValidationSchema = yup.object<IAuthApiLoginRequest>().shape({
  username: yup.string().email().required(),
  password: yup.string().required(),
  rememberMe: yup.boolean().optional(),
});

export const SignInForm: FC<IRegisterFormProps> = ({ onSubmit }) => {
  return (
    <SimpleForm<IAuthApiLoginRequest> onSubmit={onSubmit} validationSchema={signInValidationSchema}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <TextField name="username" label="Email" />
          <TextField name="password" label="Password" type="password" />
          <CheckboxField name="rememberMe" label="Remember me on this device" />
          <SubmitButton>Log In</SubmitButton>
        </form>
      )}
    </SimpleForm>
  );
};
