import * as React from "react";
import { PropsWithChildren } from "react";
import classNames from "classnames";
import { Spacing } from "../Spacing";

import styles from "./_Position.module.scss";
import { IPositionProps } from "./IPositionProps";

export const Position: React.FC<PropsWithChildren<IPositionProps>> = ({
  children,
  promote,
  className,
  fixed,
  sticky,
  right,
  left,
  bottom,
  top,
  mobileRelative = false,
  style = {},
  verticalMiddle,
  horizontalMiddle,
  ...spacingProps
}) => {
  return (
    <Spacing
      className={classNames("sr-position", styles.wrapper, className, {
        [styles.verticalMiddle]: verticalMiddle,
        [styles.horizontalMiddle]: horizontalMiddle,
        [styles.mobileRelative]: mobileRelative,
        [styles.promote]: !!promote,
        [styles.fixed]: !!fixed,
        [styles.sticky]: !!sticky,
        [styles.right]: typeof right === "boolean" && right,
        [styles.left]: typeof left === "boolean" && left,
        [styles.bottom]: typeof bottom === "boolean" && bottom,
        [styles.top]: typeof top === "boolean" && top,
      })}
      fullWidth={false}
      {...spacingProps}
      style={{
        ...style,
        // TODO: do we need dynamic z-index?
        left: typeof left === "number" ? `${left}px` : typeof left === "string" ? left : undefined,
        right: typeof right === "number" ? `${right}px` : typeof right === "string" ? right : undefined,
        bottom: typeof bottom === "number" ? `${bottom}px` : typeof bottom === "string" ? bottom : undefined,
        top: typeof top === "number" ? `${top}px` : typeof top === "string" ? top : undefined,
      }}
    >
      {children}
    </Spacing>
  );
};

Position.displayName = "Position";
