import { useToast, UseToastOptions } from "@chakra-ui/react";

export const useExceptionToast = () => {
  const toast = useToast();
  return (error: any, options?: Omit<UseToastOptions, "description">) => {
    toast({
      title: "An error occurred.",
      description: error.message || error,
      status: "error",
      duration: 10000,
      isClosable: true,
      ...options,
    });
  };
};
