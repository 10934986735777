// POLYFILLS
// This must be the first line in src/index.js
import * as smoothscroll from "smoothscroll-polyfill";
import React from "react";
import Logger from "js-logger";
import { initializeDL } from "@serverready-ui/design-language/dist/init";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";

// This will make sure all browsers support the scroll options parameter instead of just x,y
(window as any).__forceSmoothScrollPolyfill__ = true;
smoothscroll.polyfill();
initializeDL();

Logger.info("Application configured, loading content.");

// Render the react app.
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
