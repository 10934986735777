import "./_ErrorMessage.scss";

import React, { PropsWithChildren } from "react";

import { Column, SingleColumnLayout } from "../../layout";
import { Header, Paragraph } from "../../typography";

interface ErrorMessageProps {
  title: string;
  message?: string;
}

export const ErrorMessage: React.FC<PropsWithChildren<ErrorMessageProps>> = ({ title, message = false, children }) => {
  return (
    <SingleColumnLayout maxWidth={500}>
      <Column className="error-message" col={12} md={8} lg={6}>
        <Header size="h1" marginTop="none">
          {title}
        </Header>

        {message && <Paragraph marginTop="none">{message}</Paragraph>}

        {children}
      </Column>
    </SingleColumnLayout>
  );
};

ErrorMessage.displayName = "ErrorMessage";
