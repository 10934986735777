import { FC, PropsWithChildren } from "react";
import classNames from "classnames";

import styles from "./_Container.module.scss";

interface IContainerProps {
  maxSize?: "tn" | "xs" | "sm" | "lg" | "xl"; // no md, because it's the default
}

export const Container: FC<PropsWithChildren<IContainerProps>> = ({ maxSize, children }) => {
  return (
    <div
      className={classNames(styles.container, {
        [styles.sizeTn]: maxSize === "tn",
        [styles.sizeXs]: maxSize === "xs",
        [styles.sizeSm]: maxSize === "sm",
        [styles.sizeLg]: maxSize === "lg",
        [styles.sizeXl]: maxSize === "xl",
      })}
    >
      {children}
    </div>
  );
};
