import React, { PropsWithChildren } from "react";
import { Helmet } from "react-helmet";

export interface IRouteHelmetProps {
  title?: string;
  canonical?: string;
}

export const RouteHelmet: React.FC<PropsWithChildren<IRouteHelmetProps>> = ({ title, canonical, children }) => {
  return (
    <Helmet>
      {title ? <title>{title}</title> : null}
      {canonical ? <link rel="canonical" href={canonical} /> : null}
      {children}
    </Helmet>
  );
};
