import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig, Method } from "axios";
import Logger from "js-logger";
import { JWTStorageService } from "@serverready-ui/core";
import { IApiClient } from "./IApiClient";
import { ApiErrors, getExceptionMessages } from "./ApiErrors";
import { IAuthApiLoginResponse, IAuthApiRefreshRequest } from "./services";

export interface IApiClientOptions {
  onAuth?: (error: AxiosError) => Promise<void>;
}

type AxiosConfigWithRetry = InternalAxiosRequestConfig & { _retry?: boolean };

export class DefaultApiClient implements IApiClient {
  protected options: IApiClientOptions;
  protected serverreadyApi: AxiosInstance;

  constructor(baseUrl: string, options: IApiClientOptions = {}) {
    this.options = options;
    this.serverreadyApi = axios.create({
      baseURL: baseUrl,
    });

    // Handle a 401 unauthorised
    this.serverreadyApi.interceptors.response.use(undefined, this.unauthorisedHandler);

    // Log any errors
    this.serverreadyApi.interceptors.response.use(undefined, this.logErrorHandler);
  }

  public async request<TRequestData = any, TResponseData = any>(
    method: Method,
    url: string,
    data?: TRequestData,
    customHeaders?: Record<string, string>
  ): Promise<AxiosResponse<TResponseData>> {
    // check if the token is expired
    if (JWTStorageService.isTokenExpired()) {
      // refresh the token
      const response = await this.serverreadyApi.post<IAuthApiRefreshRequest>("/auth/refresh");
      const newToken = response.data.accessToken;
      JWTStorageService.setAccessToken(newToken);
    }
    const headers = await this.getHeaders(customHeaders);
    return this.serverreadyApi.request({
      url,
      method,
      data,
      headers,
      withCredentials: true,
    });
  }

  public async get<TResponseData = any>(
    url: string,
    customHeaders?: Record<string, string>
  ): Promise<AxiosResponse<TResponseData>> {
    const headers = await this.getHeaders(customHeaders);
    return this.serverreadyApi.get(url, { headers });
  }

  public async post<TRequestData = any, TResponseData = any>(
    url: string,
    data?: TRequestData,
    customHeaders?: Record<string, string>
  ): Promise<AxiosResponse<TResponseData>> {
    const headers = await this.getHeaders(customHeaders);
    return this.serverreadyApi.post(url, data, { headers });
  }

  public async patch<TRequestData = any, TResponseData = any>(
    url: string,
    data?: TRequestData,
    customHeaders?: Record<string, string>
  ): Promise<AxiosResponse<TResponseData>> {
    const headers = await this.getHeaders(customHeaders);
    return this.serverreadyApi.patch(url, data, { headers });
  }

  public async delete<TResponseData = any>(
    url: string,
    customHeaders?: Record<string, string>
  ): Promise<AxiosResponse<TResponseData>> {
    const headers = await this.getHeaders(customHeaders);
    return this.serverreadyApi.delete(url, { headers });
  }

  protected async onUnauthorised(error: AxiosError): Promise<void> {
    const originalRequest: AxiosConfigWithRetry | undefined = error.config;
    if (!originalRequest) {
      return Promise.reject(error);
    }
    const authUrls = ["/auth/refresh", "/auth/login"];
    if (
      authUrls.indexOf(originalRequest.url || "") === -1 &&
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        const response = await this.serverreadyApi.post<IAuthApiRefreshRequest, IAuthApiLoginResponse>("/auth/refresh");
        if (!response) {
          return Promise.reject(error);
        }
        const newToken = response.accessToken;
        JWTStorageService.setAccessToken(newToken);
        return this.serverreadyApi.request(originalRequest);
      } catch (error) {
        // If we get an error here, we need to log the user out because the refresh token is invalid
        JWTStorageService.removeAccessToken();
        return Promise.reject(error);
      }
    }
  }

  /**
   * Handle 401s and show a modal to reauthenticate and set up a new session
   */
  protected unauthorisedHandler = async (error: any) => {
    if (error.config && error.response && error.response.status === 401) {
      // todo: handle better than this if statement
      if (error.config.url === "/auth/login") {
        return Promise.reject("Invalid email or password.");
      }
      // return this.onUnauthorised(error);
    }

    return Promise.reject(error);
  };

  /**
   * Handle and log API errors
   */
  protected logErrorHandler = async (error: any) => {
    if (error && error.response) {
      const errorMessages = getExceptionMessages(error);
      let errorMessage = errorMessages.length ? errorMessages[0] : error.message;
      const response: AxiosResponse = error.response;
      const level = response.status >= 500 ? Logger.error : Logger.warn;
      level(`${response.status} Response`, error.response);
      if (ApiErrors.isValidationError(errorMessage)) {
        errorMessage = ApiErrors.validationError.parse(errorMessage);
      }
      error.message = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
    }
    return Promise.reject(error);
  };

  /**
   * Get a jwtToken for the current session
   */
  protected async authToken(): Promise<string> {
    try {
      return JWTStorageService.getAccessToken() || "";
    } catch (error) {
      Logger.debug("[Api:AuthToken] - ", error);
    }
    return "";
  }

  /**
   * Get the authorisation header for the current session
   */
  protected async getHeaders(custom?: Record<string, any>) {
    const authToken = await this.authToken();
    const headers: { [key: string]: string } = custom || {};
    if (authToken) {
      headers.Authorization = `Bearer ${authToken}`;
    }
    return headers;
  }
}
