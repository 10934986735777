import { ColDef } from "ag-grid-community";
import { ITableButtonColDef } from "./types";
import { TableButton } from "./TableButton";

// custom column definition allows for typing of the cellRendererParams
type ColDefUnion<T> = ITableButtonColDef<T> | ColDef | string;

function isBasicColumn<T>(def: ColDefUnion<T>): def is string {
  return typeof def === "string";
}

export const makeTableColumn = <T extends ColDefUnion<any>>(
  column: T,
  customOptions?: ColDef
): T extends string ? ColDef : T => {
  return (
    isBasicColumn(column)
      ? {
          field: column,
          headerName: column.charAt(0).toUpperCase() + column.slice(1),
          ...customOptions,
        }
      : column
  ) as T extends string ? ColDef : T;
};

export const makeButtonColumn = <T>(
  onClick: ITableButtonColDef<T>["cellRendererParams"]["onClick"],
  fieldName: string = "action"
): ColDef => {
  return makeTableColumn<ITableButtonColDef<T>>({
    columnType: "button",
    field: fieldName,
    headerName: "",
    sortable: false,
    filter: false,
    suppressMenu: true,
    cellRenderer: TableButton,
    cellRendererParams: {
      onClick,
    },
  });
};
