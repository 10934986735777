import axios, { AxiosError } from "axios";

/**
 * Map between error codes and the UI error message.
 */
export const ApiErrors = {
  isAxios404: (e: Error) => {
    return axios.isAxiosError(e) && e.code === "404";
  },
  isValidationError: (message: string) => {
    return message.indexOf("must be a") > -1;
  },
  validationError: {
    parse: (message: string) => {
      if (message.indexOf("integer number") > -1) {
        return ApiErrors.validationError.integer(message);
      } else if (message.indexOf("a string") > -1) {
        return ApiErrors.validationError.string(message);
      }
      return message;
    },
    integer: (message: string) => {
      return message.replace("integer number", "number");
    },
    string: (message: string) => {
      return message.replace("a string", "text");
    },
  },
};

export const getExceptionMessages = (error: Error): string[] => {
  const errorData = (error as AxiosError<any>)?.response?.data;
  const errors = errorData?.message;
  if (errors) {
    return errors;
  }

  return [];
};
