/**
 * Local storage keys
 */
export const JWT_TOKEN_LABEL = "accessToken";
export const SELECTED_SERVER_KEY = "selectedServer";

/**
 * CSS constants
 */
export const NAVBAR_HEIGHT = 90;
export const SIDEBAR_WIDTH = 200;

/**
 * Image constants
 */
export const IMAGE_URL_PREFIX = "https://imagedelivery.net/o2N6M-yvcwUTEUtTt0rpuw/";
