import { IAppLinkProps } from "../AppLink/AppLink";
import { authLocations } from "../../routes";

interface NavigationBarLink extends IAppLinkProps {
  label: string;
}

export const navigationBarLinks: NavigationBarLink[] = [
  {
    to: authLocations.Logout.buildPath({}),
    label: "Logout",
  },
];
