import classNames from "classnames";
import * as React from "react";
import { PropsWithChildren } from "react";
import { IParagraphProps, Paragraph } from "@serverready-ui/design-language/dist/typography";
import { SRColors } from "@serverready-ui/design-language/dist/types";

export interface IErrorProps extends IParagraphProps {
  forField?: string;
  visible?: boolean;
  className?: string;
}

export const FormError: React.FC<PropsWithChildren<IErrorProps>> = ({
  forField,
  visible = true,
  className,
  children,
  ...paragraphProps
}) => {
  // adding tabIndex= -1 to allow the error to be focused
  return (
    <Paragraph
      tabIndex={-1}
      size="sm"
      hidden={!visible}
      data-for-field={forField}
      className={classNames("sr-form-error", className)}
      color={SRColors.ErrorDark}
      margin="none"
      marginTop={visible ? "xs" : "none"}
      marginBottom={visible ? "sm" : "none"}
      horizontalMargin="none"
      {...paragraphProps}
    >
      {children}
    </Paragraph>
  );
};

FormError.displayName = "FormError";
