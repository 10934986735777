import { IServer } from "../types";
import {
  IServerApiServerCreateResponse,
  IServerApiServerEditResponse,
  IServerApiServerGetResponse,
} from "../IServerApiResponses";

export function fromApi(
  server: IServerApiServerCreateResponse | IServerApiServerEditResponse | IServerApiServerGetResponse
): IServer {
  return {
    ...server,
    id: server.serverId,
  };
}
