import * as React from "react";
import classNames from "classnames";
import styles from "./_Spacing.module.scss";
import { ISpacingProps } from "./ISpacingProps";

/**
 * A general component to be reused by other components that require padding or margin
 *
 * To see how each unit maps to their name see `_spacing-ui.scss`
 */
export function Spacing<T>({
  element: Element = "div",
  children,
  id,
  style,
  className,
  fullWidth, // shouldn't need to set to true by default, since display: block is default
  fullHeight = false,
  hidden,
  onClick,
  tabIndex,

  // Padding
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  horizontalPadding,
  verticalPadding,

  paddingSm,
  paddingTopSm,
  paddingRightSm,
  paddingBottomSm,
  paddingLeftSm,
  horizontalPaddingSm,
  verticalPaddingSm,

  paddingMd,
  paddingTopMd,
  paddingRightMd,
  paddingBottomMd,
  paddingLeftMd,
  horizontalPaddingMd,
  verticalPaddingMd,

  paddingLg,
  paddingTopLg,
  paddingRightLg,
  paddingBottomLg,
  paddingLeftLg,
  horizontalPaddingLg,
  verticalPaddingLg,

  paddingXl,
  paddingTopXl,
  paddingRightXl,
  paddingBottomXl,
  paddingLeftXl,
  horizontalPaddingXl,
  verticalPaddingXl,

  // Margin
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  horizontalMargin,
  verticalMargin,

  marginSm,
  marginTopSm,
  marginRightSm,
  marginBottomSm,
  marginLeftSm,
  horizontalMarginSm,
  verticalMarginSm,

  marginMd,
  marginTopMd,
  marginRightMd,
  marginBottomMd,
  marginLeftMd,
  horizontalMarginMd,
  verticalMarginMd,

  marginLg,
  marginTopLg,
  marginRightLg,
  marginBottomLg,
  marginLeftLg,
  horizontalMarginLg,
  verticalMarginLg,

  marginXl,
  marginTopXl,
  marginRightXl,
  marginBottomXl,
  marginLeftXl,
  horizontalMarginXl,
  verticalMarginXl,

  ...props
}: React.PropsWithChildren<ISpacingProps & T>): React.ReactElement {
  return (
    <Element
      id={id}
      style={style}
      onClick={onClick}
      tabIndex={tabIndex}
      className={classNames(styles.spacing, className, {
        [styles.fullWidth]: fullWidth,
        [styles.fullHeight]: fullHeight,
        [styles.invisible]: hidden === true,
        "d-none": typeof hidden === "string",
        [`d-${hidden}-block`]: typeof hidden === "string",
        // margin
        [styles[`margin-${margin}`]]: !!margin,
        [styles[`margin-top-${marginTop}`]]: !!marginTop,
        [styles[`margin-bottom-${marginBottom}`]]: !!marginBottom,
        [styles[`margin-left-${marginLeft}`]]: !!marginLeft,
        [styles[`margin-right-${marginRight}`]]: !!marginRight,
        [styles[`margin-horizontal-${horizontalMargin}`]]: !!horizontalMargin,
        [styles[`margin-vertical-${verticalMargin}`]]: !!verticalMargin,
        // margin small
        [styles[`sm-margin-${marginSm}`]]: !!marginSm,
        [styles[`sm-margin-top-${marginTopSm}`]]: !!marginTopSm,
        [styles[`sm-margin-bottom-${marginBottomSm}`]]: !!marginBottomSm,
        [styles[`sm-margin-left-${marginLeftSm}`]]: !!marginLeftSm,
        [styles[`sm-margin-right-${marginRightSm}`]]: !!marginRightSm,
        [styles[`sm-margin-horizontal-${horizontalMarginSm}`]]: !!horizontalMarginSm,
        [styles[`sm-margin-vertical-${verticalMarginSm}`]]: !!verticalMarginSm,
        // margin medium
        [styles[`md-margin-${marginMd}`]]: !!marginMd,
        [styles[`md-margin-top-${marginTopMd}`]]: !!marginTopMd,
        [styles[`md-margin-bottom-${marginBottomMd}`]]: !!marginBottomMd,
        [styles[`md-margin-left-${marginLeftMd}`]]: !!marginLeftMd,
        [styles[`md-margin-right-${marginRightMd}`]]: !!marginRightMd,
        [styles[`md-margin-horizontal-${horizontalMarginMd}`]]: !!horizontalMarginMd,
        [styles[`md-margin-vertical-${verticalMarginMd}`]]: !!verticalMarginMd,
        // margin large
        [styles[`lg-margin-${marginLg}`]]: !!marginLg,
        [styles[`lg-margin-top-${marginTopLg}`]]: !!marginTopLg,
        [styles[`lg-margin-bottom-${marginBottomLg}`]]: !!marginBottomLg,
        [styles[`lg-margin-left-${marginLeftLg}`]]: !!marginLeftLg,
        [styles[`lg-margin-right-${marginRightLg}`]]: !!marginRightLg,
        [styles[`lg-margin-horizontal-${horizontalMarginLg}`]]: !!horizontalMarginLg,
        [styles[`lg-margin-vertical-${verticalMarginLg}`]]: !!verticalMarginLg,
        // margin xlarge
        [styles[`xl-margin-${marginXl}`]]: !!marginXl,
        [styles[`xl-margin-top-${marginTopXl}`]]: !!marginTopXl,
        [styles[`xl-margin-bottom-${marginBottomXl}`]]: !!marginBottomXl,
        [styles[`xl-margin-left-${marginLeftXl}`]]: !!marginLeftXl,
        [styles[`xl-margin-right-${marginRightXl}`]]: !!marginRightXl,
        [styles[`xl-margin-horizontal-${horizontalMarginXl}`]]: !!horizontalMarginXl,
        [styles[`xl-margin-vertical-${verticalMarginXl}`]]: !!verticalMarginXl,
        // padding
        [styles[`padding-${padding}`]]: !!padding,
        [styles[`padding-top-${paddingTop}`]]: !!paddingTop,
        [styles[`padding-bottom-${paddingBottom}`]]: !!paddingBottom,
        [styles[`padding-left-${paddingLeft}`]]: !!paddingLeft,
        [styles[`padding-right-${paddingRight}`]]: !!paddingRight,
        [styles[`padding-horizontal-${horizontalPadding}`]]: !!horizontalPadding,
        [styles[`padding-vertical-${verticalPadding}`]]: !!verticalPadding,
        // padding small
        [styles[`sm-padding-${paddingSm}`]]: !!paddingSm,
        [styles[`sm-padding-top-${paddingTopSm}`]]: !!paddingTopSm,
        [styles[`sm-padding-bottom-${paddingBottomSm}`]]: !!paddingBottomSm,
        [styles[`sm-padding-left-${paddingLeftSm}`]]: !!paddingLeftSm,
        [styles[`sm-padding-right-${paddingRightSm}`]]: !!paddingRightSm,
        [styles[`sm-padding-horizontal-${horizontalPaddingSm}`]]: !!horizontalPaddingSm,
        [styles[`sm-padding-vertical-${verticalPaddingSm}`]]: !!verticalPaddingSm,
        // padding medium
        [styles[`md-padding-${paddingMd}`]]: !!paddingMd,
        [styles[`md-padding-top-${paddingTopMd}`]]: !!paddingTopMd,
        [styles[`md-padding-bottom-${paddingBottomMd}`]]: !!paddingBottomMd,
        [styles[`md-padding-left-${paddingLeftMd}`]]: !!paddingLeftMd,
        [styles[`md-padding-right-${paddingRightMd}`]]: !!paddingRightMd,
        [styles[`md-padding-horizontal-${horizontalPaddingMd}`]]: !!horizontalPaddingMd,
        [styles[`md-padding-vertical-${verticalPaddingMd}`]]: !!verticalPaddingMd,
        // padding large
        [styles[`lg-padding-${paddingLg}`]]: !!paddingLg,
        [styles[`lg-padding-top-${paddingTopLg}`]]: !!paddingTopLg,
        [styles[`lg-padding-bottom-${paddingBottomLg}`]]: !!paddingBottomLg,
        [styles[`lg-padding-left-${paddingLeftLg}`]]: !!paddingLeftLg,
        [styles[`lg-padding-right-${paddingRightLg}`]]: !!paddingRightLg,
        [styles[`lg-padding-horizontal-${horizontalPaddingLg}`]]: !!horizontalPaddingLg,
        [styles[`lg-padding-vertical-${verticalPaddingLg}`]]: !!verticalPaddingLg,
        // padding xlarge
        [styles[`xl-padding-${paddingXl}`]]: !!paddingXl,
        [styles[`xl-padding-top-${paddingTopXl}`]]: !!paddingTopXl,
        [styles[`xl-padding-bottom-${paddingBottomXl}`]]: !!paddingBottomXl,
        [styles[`xl-padding-left-${paddingLeftXl}`]]: !!paddingLeftXl,
        [styles[`xl-padding-right-${paddingRightXl}`]]: !!paddingRightXl,
        [styles[`xl-padding-horizontal-${horizontalPaddingXl}`]]: !!horizontalPaddingXl,
        [styles[`xl-padding-vertical-${verticalPaddingXl}`]]: !!verticalPaddingXl,
      })}
      {...props}
    >
      {children}
    </Element>
  );
}
