import classNames from "classnames";
import * as React from "react";
import { PropsWithChildren } from "react";
import { Spacing } from "../Spacing";

import styles from "./_Column.module.scss";
import { IColumnProps } from "./IColumnProps";

export const Column: React.FC<PropsWithChildren<IColumnProps>> = ({
  children,
  className,
  style,
  id,
  order,
  onClick,
  background,
  align,
  alignSelf,
  textOverflow,

  col,
  xl,
  lg,
  md,
  sm,

  colAuto,
  colXlAuto,
  colLgAuto,
  colMdAuto,
  colSmAuto,

  offset,
  offsetXl,
  offsetLg,
  offsetMd,
  offsetSm,

  mlAuto,
  mlXlAuto,
  mlLgAuto,
  mlMdAuto,
  mlSmAuto,

  mrAuto,
  mrXlAuto,
  mrLgAuto,
  mrMdAuto,
  mrSmAuto,

  shrink,
  shrinkXl,
  shrinkLg,
  shrinkMd,
  shrinkSm,
  grow,
  growXl,
  growLg,
  growMd,
  growSm,
  center,
  fill,

  dividerLeft,
  dividerRight,

  noGutters,

  ...spacingProps
}) => {
  return (
    <Spacing
      fullWidth={false}
      color={background}
      onClick={onClick}
      id={id}
      style={!!alignSelf || !!style ? { alignSelf, ...style } : undefined}
      className={classNames("sr-col", "col", styles.srColumn, className, {
        [styles.noGutters]: !!noGutters,
        [styles.hasTextOverflow]: textOverflow,
        [styles.alignTextLeft]: align === "left",
        [styles.alignTextCenter]: align === "center",
        [styles.alignTextRight]: align === "right",
        [styles.dividerLeft]: dividerLeft,
        [styles.dividerRight]: dividerRight,
        col: typeof col === "boolean",
        [`col-${col}`]: typeof col === "number",
        [`col-xl-${xl}`]: typeof xl !== "undefined",
        [`col-lg-${lg}`]: typeof lg !== "undefined",
        [`col-sm-${sm}`]: typeof sm !== "undefined",
        [`col-md-${md}`]: typeof md !== "undefined",
        [`order-${order}`]: typeof order !== "undefined",
        [`offset-${offset}`]: typeof offset !== "undefined",
        [`offset-xl-${offsetXl}`]: typeof offsetXl !== "undefined",
        [`offset-lg-${offsetLg}`]: typeof offsetLg !== "undefined",
        [`offset-md-${offsetMd}`]: typeof offsetMd !== "undefined",
        [`offset-sm-${offsetSm}`]: typeof offsetSm !== "undefined",
        [`flex-shrink-${shrink}`]: typeof shrink !== "undefined",
        [`flex-xl-shrink-${shrinkXl}`]: typeof shrinkXl !== "undefined",
        [`flex-lg-shrink-${shrinkLg}`]: typeof shrinkLg !== "undefined",
        [`flex-md-shrink-${shrinkMd}`]: typeof shrinkMd !== "undefined",
        [`flex-sm-shrink-${shrinkSm}`]: typeof shrinkSm !== "undefined",
        [`flex-grow-${grow}`]: typeof grow !== "undefined",
        [`flex-xl-grow-${growXl}`]: typeof growXl !== "undefined",
        [`flex-lg-grow-${growLg}`]: typeof growLg !== "undefined",
        [`flex-md-grow-${growMd}`]: typeof growMd !== "undefined",
        [`flex-sm-grow-${growSm}`]: typeof growSm !== "undefined",

        "ml-auto": mlAuto,
        "ml-xl-auto": mlXlAuto,
        "ml-lg-auto": mlLgAuto,
        "ml-md-auto": mlMdAuto,
        "ml-sm-auto": mlSmAuto,
        "mr-auto": mrAuto,
        "mr-xl-auto": mrXlAuto,
        "mr-lg-auto": mrLgAuto,
        "mr-md-auto": mrMdAuto,
        "mr-sm-auto": mrSmAuto,
        "col-xl-auto": colXlAuto,
        "col-lg-auto": colLgAuto,
        "col-md-auto": colMdAuto,
        "col-sm-auto": colSmAuto,
        "col-auto": colAuto,
        "col-center": center === true,
        "flex-fill": fill,
      })}
      {...spacingProps}
    >
      {children}
    </Spacing>
  );
};

Column.displayName = "Column";
