// Table.tsx
import React, { forwardRef, Ref, useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef, GridOptions } from "ag-grid-community";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import classNames from "classnames";
import styles from "./_Table.module.scss";
import { makeTableColumn } from "./makeTableColumn";

interface TableProps<T> {
  columns: (ColDef | string)[];
  rowData: T[];
  globalColumnOptions?: ColDef;
  gridOptions?: Omit<GridOptions, "defaultColDef">;
}

/**
 * Common table component that uses ag-grid-react
 * NOTE: default behaviour is:
 *  - height is set to 100%
 *  - gridOptions.domLayout is set to "autoHeight"
 * @param ref
 * @param columns
 * @param rowData
 * @param globalColumnOptions
 * @param customGridOptions
 * @constructor
 */
function TableComponent<T>(
  { columns, rowData, globalColumnOptions, gridOptions: customGridOptions }: TableProps<T>,
  ref: Ref<AgGridReact>
) {
  const [columnDefs] = useState<ColDef[]>(
    columns.map((column: string | ColDef) => ({
      ...(typeof column === "string" ? makeTableColumn(column) : column),
    }))
  );
  const [data, setData] = useState<T[]>(rowData);
  useEffect(() => {
    setData(rowData);
  }, [rowData]);

  const gridOptions: GridOptions<T> = React.useMemo(() => {
    return {
      domLayout: "autoHeight",
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        suppressMovable: true,
        flex: 1,
        ...globalColumnOptions,
      },
      suppressRowClickSelection: true,
      suppressCellFocus: true,
      // onGridReady: event => event.api.sizeColumnsToFit(),
      ...customGridOptions,
    };
  }, [globalColumnOptions, customGridOptions]);

  useEffect(() => {
    if (gridOptions.api) {
      gridOptions.api.setColumnDefs(columnDefs);
      gridOptions.api.setRowData(data);
    }
  }, [gridOptions, columnDefs, data]);

  return (
    <div
      className={classNames("ag-theme-alpine-dark", styles.agThemeOverride)}
      style={{ width: "100%", height: "100%" }}
    >
      <AgGridReact ref={ref} {...gridOptions} columnDefs={columnDefs} rowData={data} />
    </div>
  );
}

export const Table = forwardRef(TableComponent) as <T>(
  props: React.PropsWithChildren<TableProps<T>> & { ref?: Ref<AgGridReact<T>> }
) => React.ReactElement;
