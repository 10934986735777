import * as React from "react";
import { PropsWithChildren } from "react";
import { ITextProps, Text } from "../Text/Text";

export interface ISpanProps extends ITextProps {}

export const Span: React.FC<PropsWithChildren<ISpanProps>> = ({ children, ...props }) => {
  return (
    <Text inline size="inherit" weight="inherit" color="inherit" margin="none" fullWidth={false} {...props}>
      {children}
    </Text>
  );
};

Span.displayName = "Span";
