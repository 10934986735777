import classNames from "classnames";
import { FC, PropsWithChildren, ReactElement } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Paragraph, Span, Text } from "../../typography";
import { TypographyFontSize } from "../../types";
import styles from "./_Label.module.scss";

interface ILabelProps {
  className?: string;
  explainer?: string | ReactElement;
  disabled?: boolean;
  size?: TypographyFontSize;
  labelFor: string;
  icon?: IconProp;
}

export const Label: FC<PropsWithChildren<ILabelProps>> = ({
  className,
  explainer,
  children,
  disabled,
  size = "md",
  icon,
  labelFor,
}) => {
  return (
    <label htmlFor={labelFor} className={classNames("sr-label", styles.label, className)}>
      <div className="form-item-header">
        <Text className={styles.formLabel} weight="semibold" aria-disabled={disabled} size={size} margin="none">
          {!!icon && (
            <Span marginRight="xs">
              <FontAwesomeIcon icon={icon} />
            </Span>
          )}
          {children}
        </Text>
        {explainer ? (
          typeof explainer === "string" ? (
            <Paragraph className={styles.formExplainer} marginTop="xs" marginBottom="none">
              {explainer}
            </Paragraph>
          ) : (
            explainer
          )
        ) : null}
      </div>
    </label>
  );
};
