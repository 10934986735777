import { route } from "react-router-typesafe-routes/dom";

export const authLocations = route(
  "auth",
  {},
  {
    Logout: route("logout"),
    Register: route("register"),
    SignIn: route("sign-in"),
    // AdminSignIn: route("admin/signin"), // TODO admin? probably not high priority
  }
);
