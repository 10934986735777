import React, { FC, PropsWithChildren } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Logger from "js-logger";
import { useAuthenticatedUserContext } from "@serverready-ui/auth";
import { authLocations } from "./locations";

export const AuthenticatedLayout: FC<PropsWithChildren> = ({ children }) => {
  const { isLoggedIn } = useAuthenticatedUserContext();
  const location = useLocation();

  if (!isLoggedIn) {
    // TODO: deep link set to current location so that we can redirect back to it after sign in
    //  use localStorage for this, or database if we want to persist it across the account?
    //   - need a localstorage service for this then
    const redirect = {
      pathname: authLocations.SignIn.buildPath({}),
      search: location.search,
    };
    Logger.info(`redirecting user`, { redirect });
    return <Navigate to={redirect} />;
  }

  return <>{children || <Outlet />}</>;
};
