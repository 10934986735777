import "./_NavigationBar.module.scss";

import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { Column, Row } from "../../layout";
import { Paragraph } from "../../typography";

import logo from "../../assets/images/sr-logo.png";
import { ImageComponent } from "../../core";
import styles from "./_NavigationBar.module.scss";

export interface INavigationBarProps {
  back?: React.ReactNode;
  className?: string;
  brandTo?: string | null;
  title?: React.ReactNode;
}

export const NavigationBar: React.FC<PropsWithChildren<INavigationBarProps>> = ({
  className,
  children,
  title,
  brandTo = "/",
  back,
}) => {
  return (
    <div className={classNames(styles["sr-navigation-bar"], className, {})}>
      <Row>
        <Column shrink={1} grow={0}>
          <a href={brandTo as Exclude<typeof brandTo, null>}>
            <ImageComponent src={logo} alt="ServerReady" height="60px" width="60px" block />
          </a>
        </Column>

        {!!back && <Column>{back}</Column>}
        {title && (
          <Column>
            {!!title && typeof title === "string" ? (
              <Paragraph margin="none" weight="semibold" className="nav-title">
                {title}
              </Paragraph>
            ) : (
              title
            )}
          </Column>
        )}
        {children && <Column>{children}</Column>}
      </Row>

      {/* TODO: Mobile menu */}
    </div>
  );
};

NavigationBar.displayName = "NavigationBar";
