import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { Spacing } from "../../layout/Spacing/Spacing";
import { SRColor, TypographyFontSize, TypographyWeight, UISpacingSize } from "../../types";
import { ISpacingProps } from "../../layout";
import styles from "./_Text.module.scss";

export type HeaderTag = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
export type HTMLTextTag = "p" | "span" | HeaderTag;
export type TextTransform = "uppercase" | "lowercase" | "capitalize" | "none";
export type TextAlign = "left" | "center" | "right";
export type TextDecoration = "underline" | "line-through" | "none";

export interface ITextProps extends ISpacingProps {
  id?: string;
  tag?: HTMLTextTag;
  size?: TypographyFontSize | "inherit";
  weight?: TypographyWeight | "inherit";
  color?: SRColor | "inherit";
  transform?: TextTransform;
  textDecoration?: TextDecoration;
  align?: TextAlign;
  className?: string;
  italic?: boolean;
  inline?: boolean;
  forceSingleLine?: boolean;
  style?: React.CSSProperties;
  margin?: UISpacingSize;
  onClick?: (event: React.MouseEvent) => void;
  alignSm?: TextAlign;
}

/**
 * A base component that implements consistent typography styles (sizes, colors, margins, etc...).
 * To see how text sizes map to their names see `_type-body.scss`
 *
 * Note: `Text` **should not** be used on it's own (e.g. instead of a `Paragraph` or `Header`) but instead
 *  you should use this as a base to compose other typographic components.
 */
export const Text: React.FC<PropsWithChildren<ITextProps>> = ({
  id,
  tag: Tag = "span",
  size = "md",
  weight = "md",
  color,
  textDecoration,
  alignSm,
  transform,
  align,
  children,
  className,
  onClick,
  italic,
  inline,
  forceSingleLine,
  style,
  fullWidth,
  ...spacingProps
}) => {
  const mergedStyles = style || {};
  const styleColor = color?.startsWith("#") || color?.startsWith("rgba");

  if (styleColor) {
    mergedStyles.color = color;
  }

  if (typeof textDecoration === "string") {
    mergedStyles.textDecoration = textDecoration;
  }

  return (
    <Spacing
      id={id}
      element={Tag}
      style={Object.keys(mergedStyles).length > 0 ? mergedStyles : undefined}
      className={classNames("sr-text", className, {
        [styles.weightInherit]: weight === "inherit",
        [styles.weightLight]: weight === "light",
        [styles.weightRegular]: weight === "regular",
        [styles.weightSemibold]: weight === "semibold",
        [styles.sizeTitle]: size === "title",
        [styles.sizeH3]: size === "h3",
        [styles.sizeH2]: size === "h2",
        [styles.sizeH1]: size === "h1",
        [styles.sizeLg]: size === "lg",
        [styles.sizeMd]: size === "md",
        [styles.sizeSm]: size === "sm",
        [styles.sizeXs]: size === "xs",
        [styles.transformUppercase]: transform === "uppercase",
        [styles.transformLowercase]: transform === "lowercase",
        [styles.transformCapitalize]: transform === "capitalize",
        [styles.alignLeft]: align === "left",
        [styles.alignCenter]: align === "center",
        [styles.alignRight]: align === "right",
        [styles.alignLeftSm]: alignSm === "left",
        [styles.alignCenterSm]: alignSm === "center",
        [styles.alignRightSm]: alignSm === "right",
        [styles.forceSingleLine]: forceSingleLine,
        [styles.italic]: !!italic,
        [styles.inline]: !!inline,
      })}
      fullWidth={fullWidth}
      onClick={onClick}
      {...spacingProps}
    >
      {children}
    </Spacing>
  );
};

Text.displayName = "Text";
