import React, { FC, ReactElement } from "react";
import { Field, FieldRenderProps } from "react-final-form";
import Logger from "js-logger";
import { FormFieldLabel } from "@serverready-ui/design-language/dist/input";
import { IFormField } from "@serverready-ui/design-language/dist/types";
import { FormError } from "../FormError/FormError";

export const parseError = (error: any): string => {
  if (typeof error === "undefined") {
    return "";
  }
  if (typeof error === "string") {
    return error;
  }
  if (typeof error === "object") {
    return Object.values(error).join(" ");
  }
  Logger.warn("Unknown error type", error);
  return "";
};

export interface FormFieldProps extends IFormField {
  type?: string;
  value?: any;
  defaultValue?: any;
  children: (props: FieldRenderProps<any, any>) => ReactElement;
}

export const FormField: FC<FormFieldProps> = ({
  name,
  label,
  explainer,
  disabled,
  children,
  hidden,
  labelSize,
  format,
  parse,
  formatOnBlur,
  icon,
  // Used for radios
  value,
  defaultValue,
  type,
  optional,
  ...spacingProps
}) => {
  return (
    <Field
      name={name}
      format={!!format ? format : undefined}
      parse={!!parse ? parse : undefined}
      formatOnBlur={formatOnBlur}
      type={type}
      value={value}
      defaultValue={defaultValue}
    >
      {({ meta, input }) => {
        return (
          !hidden && (
            <FormFieldLabel
              label={label}
              disabled={disabled}
              explainer={explainer}
              labelSize={labelSize}
              name={name}
              icon={icon}
              optional={optional}
              {...spacingProps}
            >
              {children({ meta, input })}
              <FormError forField={name} visible={!!meta.error && meta.touched}>
                {meta.error && meta.touched && parseError(meta.error)}
              </FormError>
            </FormFieldLabel>
          )
        );
      }}
    </Field>
  );
};

FormField.displayName = "FormField";
