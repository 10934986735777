import React from "react";
import ReactSelect from "react-select";
import { ISelectable, SRColors } from "../../types";
import { ISelectProps } from "./ISelectProps";

export const getDefaultSelectStyles = <T,>(options?: Partial<ISelectProps<T>>) => ({
  singleValue: original => ({ ...original, color: SRColors.Neutral90, fontWeight: 600 }),
  option: (original, state) => {
    return {
      ...original,
      cursor: "pointer",
      color: SRColors.Neutral90,
      backgroundColor: state.isSelected ? SRColors.BrandLightDp24 : SRColors.SurfaceBlackBrandMixDp97,
      "&:hover": {
        backgroundColor: SRColors.BrandLightDp24,
      },
    };
  },
  menu: original => ({
    ...original,
    backgroundColor: SRColors.SurfaceBlackBrandMixDp97,
    // border: `1px solid ${SRColors.Neutral30}`,
    width: "100%",
  }),
  valueContainer: original => ({
    ...original,
    color: options?.isDisabled ? SRColors.Neutral45 : SRColors.Neutral90,
  }),
  indicatorsContainer: original => {
    return {
      ...original,
      // Style the clear indicator
      "div:first-of-type": {
        transition: "color 0.1s linear",
        color: options?.isDisabled ? SRColors.Neutral45 : SRColors.Neutral90,
        "&:hover": {
          color: options?.isDisabled ? SRColors.Neutral45 : SRColors.Neutral90,
        },
      },
    };
  },
  indicatorSeparator: original => {
    return {
      ...original,
      backgroundColor: SRColors.Neutral60,
      margin: "0 8px",
    };
  },
  menuList: original => {
    return {
      ...original,
      minWidth: "240px",
      backgroundColor: SRColors.SurfaceBlackBrandMixDp97,
      border: `1px solid ${SRColors.Neutral30}`,
      borderRadius: `4px`,
      padding: "16px 0",
    };
  },
  control: (original, state) => {
    return {
      ...original,
      padding: "8px",
      backgroundColor: SRColors.SurfaceBlackBrandMixDp97,
      border: `1px solid ${SRColors.Neutral30}`,
      borderRadius: `4px`,
      cursor: "pointer",
      fontFamily: "sofiapro-regular",
      "&:hover": {
        borderColor: SRColors.Brand,
      },
    };
  },
});

export function Select<T = string, Async = false>({
  invalid,
  isDisabled,
  components: reactSelectComponents = {},
  styles: reactSelectStyles = {},
  value,
  options,
  onChange,
  ...restProps
}: ISelectProps<T, Async>): React.ReactElement {
  const selectedOption = options?.find(option => option.value === value);
  // const RenderSelect = isAsyncSelectProps<T>(restProps) ? AsyncSelect : ReactSelect;
  return (
    <div>
      <ReactSelect<ISelectable<T>>
        classNamePrefix="sr-select"
        styles={{ ...getDefaultSelectStyles({ isDisabled }), ...reactSelectStyles } as any}
        // TODO: allow custom components?
        isDisabled={isDisabled}
        options={options}
        value={selectedOption}
        getOptionLabel={option => option.label}
        onChange={(value, meta) => {
          if (value) {
            onChange?.(value.value, meta);
          }
        }}
        {...restProps}
      />
    </div>
  );
}
