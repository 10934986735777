import React, { FC, PropsWithChildren } from "react";
import classNames from "classnames";
import { IFormField, SRColors } from "../../types";
import { ISpacingProps, Spacing } from "../../layout";
import { Text } from "../../typography";
import { Label } from "../Label/Label";

export interface IFormFieldLabelProps
  extends Pick<
    IFormField,
    | "label"
    | "labelAppend"
    | "disabled"
    | "explainer"
    | "labelSize"
    | "name"
    | "icon"
    | "optional"
    | keyof ISpacingProps
  > {}

/**
 * A wrapper for a form field.
 */
export const FormFieldLabel: FC<PropsWithChildren<IFormFieldLabelProps>> = ({
  label,
  disabled,
  explainer,
  labelSize,
  name,
  icon,
  optional,
  children,
  marginBottom = "sm",
  labelAppend,
  ...spacingProps
}) => {
  return (
    <Spacing className={classNames("sr-form-field")} marginBottom={marginBottom} {...spacingProps}>
      <>
        {!!label && (
          <Label disabled={disabled} explainer={explainer} size={labelSize} labelFor={name} icon={icon}>
            {label}
            {!optional && (
              <Text
                color={SRColors.ErrorDark}
                marginLeft="tn"
                style={{
                  verticalAlign: "super",
                }}
              >
                *
              </Text>
            )}
            {labelAppend}
          </Label>
        )}
        {children}
      </>
    </Spacing>
  );
};
