import { IApiClient } from "../../IApiClient";
import { IProduct } from "./types";
import { IProductApiCreateRequest, IProductApiUpdateRequest } from "./IProductApiRequests";
import { IProductApiProductListResponse } from "./IProductApiResponses";
import { fromApi, fromApiList, toApiCreate, toApiUpdate } from "./transform/productTransform";

export class ProductApi {
  private client: IApiClient;

  constructor(client: IApiClient) {
    this.client = client;
  }

  public urlForGet(storeId: string, productId: string) {
    return this.urlForProduct(`${storeId}/${productId}`);
  }

  public urlForList(storeId: string) {
    return this.urlForProduct(storeId);
  }

  public urlForCreate(storeId: string) {
    return this.urlForProduct(`create/${storeId}`);
  }

  public urlForUpdate(storeId: string, productId: string) {
    return this.urlForProduct(`update/${storeId}/${productId}`);
  }

  public urlForDelete(storeId: string, productId: string) {
    return this.urlForProduct(`delete/${storeId}/${productId}`);
  }

  public async get(storeId: string, productId: string): Promise<IProduct> {
    const { data } = await this.client.get<IProduct>(this.urlForGet(storeId, productId));
    return fromApi(data);
  }

  public async list(storeId: string): Promise<IProductApiProductListResponse> {
    const { data } = await this.client.get<IProductApiProductListResponse>(this.urlForList(storeId));
    return fromApiList(data);
  }

  public async create(storeId: string, body: IProductApiCreateRequest): Promise<IProduct> {
    const { data } = await this.client.post<IProductApiCreateRequest, IProduct>(
      this.urlForCreate(storeId),
      toApiCreate(body)
    );
    return fromApi(data);
  }

  public async update(storeId: string, productId: string, body: IProductApiUpdateRequest): Promise<IProduct> {
    const { data } = await this.client.patch<IProductApiUpdateRequest, IProduct>(
      this.urlForUpdate(storeId, productId),
      toApiUpdate(body)
    );
    return fromApi(data);
  }

  public async delete(storeId: string, productId: string): Promise<void> {
    await this.client.delete(this.urlForDelete(storeId, productId));
  }

  private urlForProduct(path: string = "") {
    return "/products" + (path ? "/" + path : "");
  }
}
