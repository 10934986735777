import { useApiContext } from "@serverready-ui/api";
import { useAuthenticatedUserContext } from "@serverready-ui/auth";

export const useLogout = () => {
  const { Auth } = useApiContext();
  const { isLoggedIn } = useAuthenticatedUserContext();
  return async () => {
    if (isLoggedIn) {
      void Auth.logout(); // 401's if not logged in
    }
  };
};
