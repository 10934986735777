import { FC } from "react";
import { Input, InputProps } from "@serverready-ui/design-language/dist/input";
import { IFormField } from "@serverready-ui/design-language/dist/types";
import { FormField } from "../FormField/FormField";

// Props from Input that we want to limit, or that we now get from FormField
type OmitInputProps = "onBlur" | "onChange" | "onFocus" | "value" | "disabled" | "invalid" | "size";

export interface TextFieldProps extends Omit<IFormField, "format" | "formatOnBlur">, Omit<InputProps, OmitInputProps> {
  className?: string;
  maxWords?: number;
  minRows?: number;
  format?: (value: any, name: string) => any;
}

export const TextField: FC<TextFieldProps> = ({
  name,
  label,
  labelSize,
  explainer,
  disabled,
  type,
  hidden,
  marginBottom,
  format,
  optional,
  ...restProps
}) => {
  return (
    <FormField
      name={name}
      label={label}
      labelSize={labelSize}
      disabled={disabled}
      explainer={explainer}
      hidden={hidden}
      marginBottom={marginBottom}
      optional={optional}
      format={format}
    >
      {({ meta, input }) => {
        return (
          <Input disabled={disabled} invalid={meta.touched && meta.invalid} {...restProps} {...input} type={type} />
        );
      }}
    </FormField>
  );
};

TextField.displayName = "TextField";
