import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  authLocations,
  NotFoundView,
  serverLocations,
  storeLocations,
  UnauthenticatedLayout,
  userLocations,
} from "@serverready-ui/routing";
import { Loading } from "@serverready-ui/design-language/dist/core";
import { AuthenticatedLayout } from "@serverready-ui/routing/dist/routes/AuthenticatedLayout";
import { LogoutView, RegisterView, SignInView } from "./pages/authorisation";

const ServerRouter = React.lazy(() => import("@serverready-ui/module-server"));
const StoreRouter = React.lazy(() => import("@serverready-ui/module-store"));
const UserRouter = React.lazy(() => import("@serverready-ui/module-user"));

export const ApplicationRouter = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="" element={<Navigate to={authLocations.SignIn.buildPath({})} replace />} />

        <Route
          path={authLocations.SignIn.path}
          element={
            <UnauthenticatedLayout>
              <SignInView />
            </UnauthenticatedLayout>
          }
        />
        <Route
          path={authLocations.Register.path}
          element={
            <UnauthenticatedLayout>
              <RegisterView />
            </UnauthenticatedLayout>
          }
        />

        <Route
          path={authLocations.Logout.path}
          element={
            <AuthenticatedLayout>
              <LogoutView />
            </AuthenticatedLayout>
          }
        />

        <Route
          path={userLocations.path + "/*"}
          element={
            <AuthenticatedLayout>
              <UserRouter />
            </AuthenticatedLayout>
          }
        />
        <Route
          path={serverLocations.path + "/*"}
          element={
            <AuthenticatedLayout>
              <ServerRouter />
            </AuthenticatedLayout>
          }
        />
        <Route
          path={storeLocations.path + "/*"}
          element={
            <AuthenticatedLayout>
              <StoreRouter />
            </AuthenticatedLayout>
          }
        />
        <Route path="*" element={<NotFoundView />} />
      </Routes>
    </Suspense>
  );
};
