import { route } from "react-router-typesafe-routes/dom";

export const storeLocations = route(
  "store",
  {},
  {
    Dashboard: route(""),
    View: route("view"),
    Products: route(
      "products",
      {},
      {
        Create: route("create"),
        Edit: route("edit/:productId"),
      }
    ),
    Collections: route("collections"),
  }
);
