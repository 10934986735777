import { flatMap, isUndefined } from "lodash";
import useSWR, { Fetcher, Key, KeyedMutator, SWRConfiguration, SWRResponse } from "swr";

import { NotUndefined } from "../utils";
import { useImmutableSWR } from "./useImmutableSWR";

export type ServiceResponse<T, K = T> = [T | undefined, IServiceMeta, KeyedMutator<K>];

export interface IServiceMeta {
  loading: boolean;
  error?: Error;
}

export interface IMultipleServiceHooksMeta<TResponses> {
  responses: TResponses;
  errors: Error[];
  hasErrors: boolean;
  loading: boolean;
}

export interface IPaginationServiceMeta<T> extends IServiceMeta {
  setPage: (page: number) => Promise<Array<T> | undefined>;
  page: number;
  isLoadingInitialPage: boolean;
  isFinalPage: boolean;
}

export function swrToServiceResponse<T>({ data, error, mutate }: SWRResponse<T, Error>): ServiceResponse<T> {
  return [data, { loading: isUndefined(data) && isUndefined(error), error }, mutate];
}

export function useSWRService<Data = any>(
  key: Key | null,
  fetcher: Fetcher<Data> | null,
  options?: SWRConfiguration<Data, Error>
): ServiceResponse<Data> {
  return swrToServiceResponse(useSWR(key, fetcher, options));
}

export function useImmutableSWRService<Data = any>(
  key: Key | null,
  fetcher: Fetcher<Data> | null,
  options?: SWRConfiguration<Data, Error>
): ServiceResponse<Data> {
  return swrToServiceResponse(useImmutableSWR(key, fetcher, options));
}

function isMultipleServiceHooksMeta(
  meta: IMultipleServiceHooksMeta<any> | IServiceMeta
): meta is IMultipleServiceHooksMeta<any> {
  return meta.hasOwnProperty("errors");
}

export function combinedServiceHookMeta(
  metas: (IServiceMeta | IMultipleServiceHooksMeta<any>)[]
): Omit<IMultipleServiceHooksMeta<any>, "responses"> {
  const errors = flatMap(metas, m => (isMultipleServiceHooksMeta(m) ? m.errors : m.error)).filter(NotUndefined);
  const hasErrors = errors.length > 0;
  const loading = !hasErrors && metas.some(m => m.loading);
  return { loading, errors, hasErrors };
}
