import { FC } from "react";
import { Page } from "@serverready-ui/design-language/dist/layout";
import { NotFoundContent } from "./NotFoundContent";

export const NotFoundView: FC = () => {
  return (
    <Page title="Not Found">
      <NotFoundContent />
    </Page>
  );
};
