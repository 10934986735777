import * as React from "react";
import { PropsWithChildren } from "react";
import classNames from "classnames";
import { ThreeBounce } from "better-react-spinkit";
import { Paragraph } from "../../typography";
import { ISpacingProps, Spacing } from "../../layout";
import { SRColor, SRColors } from "../../types";
import styles from "./_Loading.module.scss";

interface LoadingProps extends ISpacingProps {
  color?: SRColor;
}

export const Loading: React.FC<PropsWithChildren<LoadingProps>> = ({
  children,
  color = SRColors.BrandLight300,
  ...spacingProps
}) => (
  <Spacing className={classNames(styles.loading, "sr-loading")} verticalPadding="md" {...spacingProps}>
    <ThreeBounce color={color} />
    {children && <Paragraph margin="sm">{children}</Paragraph>}
  </Spacing>
);

Loading.displayName = "Loading";
