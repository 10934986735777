import { getNumberOfWords } from "@serverready-ui/core";
import * as yup from "yup";

declare module "yup" {
  interface StringSchema {
    maxWords(maxLength: number, message: string): StringSchema;
  }

  interface NumberSchema {
    decimal(maxDecimals: number, message: string): NumberSchema;
  }
}

export const yupValidators = () => {
  yup.addMethod<yup.StringSchema>(yup.string, "maxWords", function (maxLength: number, message: string) {
    return this.test("max-words", message, value => {
      if (value) {
        return getNumberOfWords(value) <= maxLength;
      }
      return true;
    });
  });

  yup.addMethod<yup.NumberSchema>(yup.number, "decimal", function (maxDecimals: number, message: string) {
    return this.test("decimal", message, value => {
      if (!value) {
        return true;
      }
      const regex = new RegExp(`^\\d+(\\.\\d{1,${maxDecimals}})?$`);
      return regex.test(String(value));
    });
  });
};
