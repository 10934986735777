import { FC } from "react";
import { Page, SingleColumnLayout, Spacing } from "@serverready-ui/design-language/dist/layout";
import { Header } from "@serverready-ui/design-language/dist/typography";
import { Card, ImageComponent } from "@serverready-ui/design-language/dist/core";
import { IAuthApiRegisterRequest, useApiContext } from "@serverready-ui/api";
import { useExceptionToast } from "@serverready-ui/core";
import logo from "../../../assets/sr/ServerReady_Logo_Slim.png";
import { RegisterForm } from "./RegisterForm";

export const RegisterView: FC = () => {
  const exceptionToast = useExceptionToast();
  const api = useApiContext();
  const onSubmit = async (values: IAuthApiRegisterRequest) => {
    try {
      await api.Auth.register(values);
    } catch (error) {
      exceptionToast(error);
    }
  };
  return (
    <Page title="Register">
      <SingleColumnLayout maxWidth={500}>
        <Spacing verticalPadding="xl">
          <ImageComponent width="300px" alignCenter src={logo} alt="ServerReady Logo" />
        </Spacing>
        <Card padding="md">
          <Header align="center">Register</Header>
          <RegisterForm onSubmit={onSubmit} />
        </Card>
      </SingleColumnLayout>
    </Page>
  );
};
