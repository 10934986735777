import { createContext } from "react";
import { ILoggedInUser } from "../types";

export interface IAuthenticatedUserContext {
  user?: ILoggedInUser;
  isLoggedIn?: boolean;
  update?: (jwt?: string) => Promise<void>;
}

export const AuthenticatedUserContext = createContext<IAuthenticatedUserContext>({});
