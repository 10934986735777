import { Api } from "@serverready-ui/api";
import { IServiceMeta, JWTStorageService, useSWRService } from "@serverready-ui/core";
import Logger from "js-logger";
import { useCallback, useEffect, useMemo, useState } from "react";
import { cache } from "swr/_internal";
import { toAuthenticatedUserContext } from "../transform/toAuthenticatedUserContext";
import { IAuthenticatedUserContext } from "./AuthenticatedUserContext";

export const useAuthenticatedUser = (api: Api): [IAuthenticatedUserContext | undefined, IServiceMeta] => {
  const [jwtToken, setJwtToken] = useState<string | undefined>(JWTStorageService.getAccessToken());
  const [data, meta, mutate] = useSWRService(api.User.urlForProfile, jwtToken ? () => api.User.profile() : null);
  const update = useCallback(
    async (jwt?: string) => {
      setJwtToken(jwt);
      const newData = jwt ? await api.User.profile() : null;
      cache.set(api.User.urlForProfile, newData as any);
      await mutate();
    },
    [api.User, mutate]
  );
  useEffect(() => {
    const handleTokenChange = async () => {
      const newToken = JWTStorageService.getAccessToken();
      await update(newToken);
    };

    window.addEventListener("storage", handleTokenChange);
    return () => window.removeEventListener("storage", handleTokenChange);
  }, [update]);

  return useMemo(() => {
    if (!jwtToken || meta.error) {
      if (meta.error) {
        Logger.error("Error getting user context", meta.error);
      }
      return [
        {
          isLoggedIn: false,
        },
        {
          loading: false,
        },
      ];
    }
    return [data ? { ...toAuthenticatedUserContext(data), update } : undefined, meta];
  }, [data, jwtToken, meta, update]);
};
