import * as React from "react";
import { PropsWithChildren } from "react";
import Logger from "js-logger";
import { IPageLayoutProps, PageLayout } from "../PageLayout/PageLayout";
import { ErrorBoundary } from "../../error";
import { IRouteHelmetProps, RouteHelmet } from "./RouteHelmet";

export interface IPageProps extends IPageLayoutProps {
  title: string;
  hasHeader?: boolean;
  metadata?: Omit<IRouteHelmetProps, "title">;
}

const getCanonical = () => {
  try {
    // for if we move to SSR
    if (typeof window === "undefined") {
      return undefined;
    }

    const canonical = new URL(window.location.toString());
    canonical.port = "";
    canonical.host = "localhost"; // TODO: environment setup, get from a core/config package?
    return canonical.toString();
  } catch (e) {
    Logger.warn("Can't determine canonical url", e);
  }
};

export const Page: React.FC<PropsWithChildren<IPageProps>> = ({
  metadata,
  title,
  hasHeader,
  children,
  ...layoutProps
}) => {
  return (
    <ErrorBoundary>
      <RouteHelmet title={title + " | ServerReady"} canonical={getCanonical()} {...metadata} />
      <PageLayout hasHeader={hasHeader} {...layoutProps}>
        {children}
      </PageLayout>
    </ErrorBoundary>
  );
};

Page.displayName = "Page";
