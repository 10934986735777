import { ThreeBounce } from "better-react-spinkit";
import classNames from "classnames";
import React, { CSSProperties, ElementType, PropsWithChildren } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SRColors } from "../../types";
import { Column, Row } from "../../layout";
import styles from "./_Button.module.scss";
import { ButtonSize, ButtonStatus, ButtonType } from "./types";

export interface IButtonProps {
  // HTML
  className?: string;
  id?: string;
  htmlType?: "submit" | "reset" | "button";
  title?: string;
  style?: CSSProperties;
  // Appearance
  type?: ButtonType;
  status?: ButtonStatus;
  size?: ButtonSize;
  loading?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  fullWidth?: boolean;
  block?: boolean;
  truncate?: boolean;
  element?: ElementType;
  grouped?: boolean;

  // Icons
  leftIcon?: IconProp;
  rightIcon?: IconProp;

  // Handlers
  onClick?: (e: React.MouseEvent) => void;
}

export const Button: React.FC<PropsWithChildren<IButtonProps>> = ({
  children,
  className,
  id,
  htmlType = "button",
  title,
  loading = false,
  disabled = false,
  size = "md",
  type = "filled",
  status = "primary",
  hidden = false,
  fullWidth = false,
  block = false,
  truncate,
  leftIcon,
  grouped,
  rightIcon,
  onClick,
  element: Element = "button",
}) => {
  const clickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    // TODO: click tracking?

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <Element
      title={title}
      className={classNames(styles.button, "sr-button", className, `type-${type}`, `size-${size}`, {
        [styles.hidden]: hidden,
        [styles.disabled]: disabled || loading,
        [styles.fullWidth]: fullWidth || truncate,
        [styles.typeFilled]: type === "filled",
        [styles.typeOutline]: type === "outline",
        [styles.typeTonal]: type === "tonal",
        [styles.typeText]: type === "text",
        [styles.statusSuccess]: status === "success",
        [styles.statusError]: status === "error",
        [styles.statusWarning]: status === "warning",
        [styles.sizeXs]: size === "xs",
        [styles.sizeSm]: size === "sm",
        [styles.sizeLg]: size === "lg",
        [styles.grouped]: grouped,
        block: block || truncate,
      })}
      id={id}
      type={htmlType}
      onClick={clickHandler}
      disabled={disabled}
    >
      {loading && <ThreeBounce className={styles.loadingIndicator} color={SRColors.Neutral60} />}
      {/* Render children as invisible so that the button dimensions don't change */}
      <Row
        className={classNames({ [styles.hiddenChildren]: loading })}
        noGutters
        verticalAlign="center"
        noWrap
        fullWidth={fullWidth}
      >
        {leftIcon && (
          <Column noGutters marginRight={!!children ? "tn" : undefined}>
            <FontAwesomeIcon icon={leftIcon} />
          </Column>
        )}
        <Column className={classNames({ "text-truncate": truncate })} noGutters grow={1}>
          {children}
        </Column>
        {rightIcon && (
          <Column noGutters marginLeft={!!children ? "tn" : undefined}>
            <FontAwesomeIcon icon={rightIcon} />
          </Column>
        )}
      </Row>
    </Element>
  );
};
