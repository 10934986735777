import classNames from "classnames";
import * as React from "react";
import { PropsWithChildren } from "react";
import styles from "./_PageLayout.module.scss";

export interface IPageLayoutProps {
  className?: string;
  style?: React.CSSProperties;
  footer?: React.ReactElement;
  hasHeader?: boolean;
  hasSidebar?: boolean;
  hasStickyFooter?: boolean;
}

export const PageLayout: React.FC<PropsWithChildren<IPageLayoutProps>> = ({
  className,
  children,
  footer = null,
  style,
  hasStickyFooter,
  hasSidebar = false,
  hasHeader = false,
}) => (
  <div
    className={classNames(styles.page, className, {
      [styles.hasHeader]: hasHeader,
      [styles.hasSidebar]: hasSidebar,
      [styles.hasStickyFooter]: !!hasStickyFooter,
    })}
    style={style}
  >
    <div className="page-content">{children}</div>
    <div className="page-footer">{footer}</div>
  </div>
);

PageLayout.displayName = "Page";
