import { JWTStorageService } from "@serverready-ui/core";
import { IApiClient } from "../../IApiClient";
import { IAuthApiLoginRequest, IAuthApiRefreshRequest, IAuthApiRegisterRequest } from "./IAuthApiRequests";
import { IAuthApiLoginResponse } from "./IAuthApiResponses";

export class AuthApi {
  private client: IApiClient;
  constructor(client: IApiClient) {
    this.client = client;
  }

  public get urlForRegister() {
    return this.urlForAuth("/register");
  }

  public get urlForLogin() {
    return this.urlForAuth("/login");
  }

  public get urlForRefresh() {
    return this.urlForAuth("/refresh");
  }

  public get urlForLogout() {
    return this.urlForAuth("/logout");
  }

  public urlForAuth(path: string) {
    return "/auth" + path;
  }

  public async register(request: IAuthApiRegisterRequest): Promise<IAuthApiLoginResponse> {
    const { data } = await this.client.post<IAuthApiRegisterRequest, IAuthApiLoginResponse>(
      this.urlForRegister,
      request
    );
    this.handleJWT(data.accessToken);
    return data;
  }

  public async login(request: IAuthApiLoginRequest): Promise<IAuthApiLoginResponse> {
    const result = await this.client.post<IAuthApiLoginRequest, IAuthApiLoginResponse>(this.urlForLogin, request);
    this.handleJWT(result.data.accessToken);
    return result.data;
  }

  public async refresh(request: IAuthApiRefreshRequest): Promise<IAuthApiLoginResponse> {
    const { data } = await this.client.post<IAuthApiRefreshRequest, IAuthApiLoginResponse>(this.urlForLogin, request);
    this.handleJWT(data.accessToken);
    return data;
  }

  public async logout(): Promise<void> {
    await this.client.post(this.urlForLogout);
    this.handleJWT();
  }

  private handleJWT(accessToken?: string) {
    JWTStorageService.setAccessToken(accessToken);
  }
}
