interface IStorageOptions {
  onError?: (error: Error) => void;
}

export class LocalStorageService {
  public static get<T>(key: string, options: IStorageOptions = {}): T | undefined {
    try {
      const value = localStorage.getItem(key);
      if (value) {
        return JSON.parse(value);
      }
      return undefined;
    } catch (err) {
      if (options.onError) {
        options.onError(new Error(err));
      }
    }
  }

  public static set<T>(key: string, value: T, options: IStorageOptions = {}): void {
    try {
      if (typeof value === "undefined") {
        this.remove(key);
      } else {
        localStorage.setItem(key, JSON.stringify(value));
      }
    } catch (err) {
      if (options.onError) {
        options.onError(new Error(err));
      }
    }
  }

  public static remove(key: string, options: IStorageOptions = {}): void {
    try {
      localStorage.removeItem(key);
    } catch (err) {
      if (options.onError) {
        options.onError(new Error(err));
      }
    }
  }
}
